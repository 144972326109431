// main: ../../theme.scss

.error404 {
	.production--form {
		* {
			box-sizing: border-box;
		}
		input[type="search"] {
			border-color: color('grey');
			border-width: 1px;
			height: $input--height;
			border-style: solid !important;

			@include media-query('>tablet') {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				margin-bottom: 0;
			}
		}
		input[type="submit"] {
			height: $input--height;
			line-height: $input--height;
			padding: 0;
			min-width: unset !important;
			width: 100%;
			@include media-query('>tablet') {
				border-top-left-radius: 0 !important;
				border-bottom-left-radius: 0 !important;
			}
		}
	}
	.four-oh-four-menu {
		ul {
			padding: 0;
			list-style: none;
			li {
				display: inline-block;
				a {
					margin: 15px;
					transition: all .3s ease-in-out;
				}
			}
		}
	}
}