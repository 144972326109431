// main: ../production.scss

$font--list:
	('heading' ('Roboto', sans-serif))
	('reading' ('Roboto', sans-serif))
	('button' ('Roboto', sans-serif))
	('fontawesome' ('Font Awesome 5 Free'))
;

$color--list:
	('white' #ffffff)
	('black' #000000)
	('darkgrey' #5e5e5e)
	('grey' #5e5e5e)
	('mediumgrey' #9E9E9E)
	('midgrey' #C8C8C8)
	('lightgrey' #F1F1F1)
	('blue' #0057B8)
	('darkblue' #004695)
	('lightblue' #0076C0)
	('green' #79b64f)
	('orange' #E35205)
;

$social--color--list:
	('facebook' #395697)
	('twitter' #50aee1)
	('linkedin' #007bb6)
	('google' #dd4b39)
	('youtube' #bb0000)
	('instagram' #125688)
	('pinterest' #cb2027)
	('dribbble' #ef558f)
;

$background--list:
	("placeholder" "url('http://placehold.it/350x150') repeat center center scroll" "cover")
	("hero" "url('http://cdn2.hubspot.net/hubfs/298890/Production/images/production.jpg') no-repeat center center" "cover")
;

$media--list:
	(default null null)
	(desk null 1168)
	(lap 1167 1024)
	(lap-and-up null 1024)
	(portable 1023 null)
	(tablet 1023 680)
	(palm 679 null)
	(xs 479 null)
;

$color--primary:            color('blue');
$color--secondary:          color('orange');
$grid--column-spacing:      3;
$grid--width:               1218px;
$grid--max-width:           1218px;
$grid--max-width-percent:   98%;
$tilt--angle:               1.5;
$text--spacing:             20px;
$text--spacing--top:        -10px;

$basic:                     50px;
$short:                     20px;
$tall:                      40px;
$grande:                    60px;
$venti:                     80px;
$trenta:                    100px;
$cuarenta:                  120px;
$cincuenta:                 140px;

$lighter:                   100;
$light:                     300;
$normal:                    400;
$medium:                    500;
$semibold:                  600;
$bold:                      700;
$bolder:                    900;

$h1--font-family:           font('heading');
$h1--font-size:             48px;
$h1--line-height:           #{ calc( 55 / 48 ) }em;
$h1--font-style:            null;
$h1--font-weight:           $normal;
$h1--text-transform:        null;
$h1--text-decoration:       null;
$h1--letter-spacing:        null;
$h1--word-spacing:          null;
$h1--color:                 color('black');

$h2--font-family:           font('heading');
$h2--font-size:             34px;
$h2--line-height:           #{ calc( 44 / 34 ) }em;
$h2--font-style:            null;
$h2--font-weight:           $normal;
$h2--text-transform:        null;
$h2--text-decoration:       null;
$h2--letter-spacing:        null;
$h2--word-spacing:          null;
$h2--color:                 color('black');

$h3--font-family:           font('heading');
$h3--font-size:             30px;
$h3--line-height:           #{ calc( 42 / 30 ) }em;
$h3--font-style:            null;
$h3--font-weight:           $bold;
$h3--text-transform:        null;
$h3--text-decoration:       null;
$h3--letter-spacing:        null;
$h3--word-spacing:          null;
$h3--color:                 color('black');

$h4--font-family:           font('heading');
$h4--font-size:             22px;
$h4--line-height:           #{ calc( 34 / 22 ) }em;
$h4--font-style:            null;
$h4--font-weight:           $bold;
$h4--text-transform:        null;
$h4--text-decoration:       null;
$h4--letter-spacing:        null;
$h4--word-spacing:          null;
$h4--color:                 color('black');

$h5--font-family:           font('heading');
$h5--font-size:             20px;
$h5--line-height:           #{ calc( 32 / 20 ) }em;
$h5--font-style:            null;
$h5--font-weight:           $bold;
$h5--text-transform:        null;
$h5--text-decoration:       null;
$h5--letter-spacing:        null;
$h5--word-spacing:          null;
$h5--color:                 color('black');

$h6--font-family:           font('heading');
$h6--font-size:             18px;
$h6--line-height:           #{ calc( 22 / 18 ) }em;
$h6--font-style:            null;
$h6--font-weight:           $bold;
$h6--text-transform:        uppercase;
$h6--text-decoration:       null;
$h6--letter-spacing:        1.5px;
$h6--word-spacing:          null;
$h6--color:                 color('blue');

$p--font-family:            font('reading');
$p--font-size:              20px;
$p--line-height:            #{ calc( 32 / 20 ) }em;
$p--font-style:             null;
$p--font-weight:            $normal;
$p--letter-spacing:         null;
$p--word-spacing:           null;
$p--color:                  color('darkgrey');

$a--font-family:            null;
$a--font-size:              null;
$a--line-height:            null;
$a--font-style:             null;
$a--font-weight:            $semibold;
$a--text-transform:         null;
$a--text-decoration:        null;
$a--letter-spacing:         null;
$a--word-spacing:           null;
$a--color:                  color('blue');

$btn--font-family:          font('button');
$btn--font-size:            20px;
$btn--line-height:          1em;
$btn--font-style:           null;
$btn--font-weight:          $bold;
$btn--text-transform:       null;
$btn--text-decoration:      none;
$btn--letter-spacing:       null;
$btn--word-spacing:         null;
$btn--vertical-padding:     16px;
$btn--horizontal-padding:   32px;
$btn--border-radius:        0;
$btn--border-width:         2px;
$btn--min-width:            200px;
$btn--shading:              10;
$btn--color:                color('white');
$btn--border-color:         color('white');
$btn--box-shadow:           null;

$input--font-family:        font('reading');
$input--font-size:          16px;
$input--line-height:        1em;
$input--font-weight:        null;
$input--text-transform:     null;
$input--letter-spacing:     null;
$input--word-spacing:       null;
$input--border-color:       color('mediumgrey');
$input--border-width:       1px;
$input--border-radius:      0;
$input--box-shadow:         null;
$input--vertical-padding:   8px;
$input--horizontal-padding: 12px;
$input--spacing:            20px;
$input--color:              color('grey');
$input--background-color:   color('white');
$input--height:             47px;

$select--dropdown-icon:     '/wp-content/plugins/im-modules/assets/dist/img/dropdown--arrow.png';

$video--play-icon-url:      '/wp-content/uploads/2019/04/video-play-icon.png';
$video--play-icon-size:     100px;

$label--font-family:        font('reading');
$label--font-size:          16px;
$label--line-height:        1em;
$label--font-style:         null;
$label--font-weight:        null;
$label--letter-spacing:     null;
$label--word-spacing:       null;
$label--color:              color('grey');
$label--spacing:            5px;

$color--list:               append($color--list,(primary $color--primary));
$color--list:               append($color--list,(secondary $color--secondary));

