// main: ../production.scss

/* ********** FONTS ********** */

@each $font in $font--list {
    .font--#{nth($font,1)}, .font--#{nth($font,1)} * {
        font-family: nth($font,2)!important;
    }
}
@for $i from 0 through 100 {
    .font--#{$i}, .font--#{$i} * {
        font-size: #{$i}px!important;
    }
}

/* ********** TEXT ********** */

* {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

h1, .h1 {
    font-family: $h1--font-family;
    font-size: $h1--font-size;
    line-height: $h1--line-height;
    font-style: $h1--font-style;
    font-weight: $h1--font-weight;
    text-transform: $h1--text-transform;
    text-decoration: $h1--text-decoration;
    letter-spacing: $h1--letter-spacing;
    word-spacing: $h1--word-spacing;
		color:$h1--color;
		margin-top: 0;
}

h2, .h2 {
    font-family: $h2--font-family;
    font-size: $h2--font-size;
    line-height: $h2--line-height;
    font-style: $h2--font-style;
    font-weight: $h2--font-weight;
    text-transform: $h2--text-transform;
    text-decoration: $h2--text-decoration;
    letter-spacing: $h2--letter-spacing;
    word-spacing: $h2--word-spacing;
		color:$h2--color;
		margin-top: 0;
}

h3, .h3 {
    font-family: $h3--font-family;
    font-size: $h3--font-size;
    line-height: $h3--line-height;
    font-style: $h3--font-style;
    font-weight: $h3--font-weight;
    text-transform: $h3--text-transform;
    text-decoration: $h3--text-decoration;
    letter-spacing: $h3--letter-spacing;
    word-spacing: $h3--word-spacing;
    color:$h3--color;
}

h4, .h4 {
    font-family: $h4--font-family;
    font-size: $h4--font-size;
    line-height: $h4--line-height;
    font-style: $h4--font-style;
    font-weight: $h4--font-weight;
    text-transform: $h4--text-transform;
    text-decoration: $h4--text-decoration;
    letter-spacing: $h4--letter-spacing;
    word-spacing: $h4--word-spacing;
    color:$h4--color;
}

h5, .h5 {
    font-family: $h5--font-family;
    font-size: $h5--font-size;
    line-height: $h5--line-height;
    font-style: $h5--font-style;
    font-weight: $h5--font-weight;
    text-transform: $h5--text-transform;
    text-decoration: $h5--text-decoration;
    letter-spacing: $h5--letter-spacing;
    word-spacing: $h5--word-spacing;
    color:$h5--color;
}

h6, .h6 {
    font-family: $h6--font-family;
    font-size: $h6--font-size;
    line-height: $h6--line-height;
    font-style: $h6--font-style;
    font-weight: $h6--font-weight;
    text-transform: $h6--text-transform;
    text-decoration: $h6--text-decoration;
    letter-spacing: $h6--letter-spacing;
    word-spacing: $h6--word-spacing;
    color:$h6--color;
}
h1, h2, h3, h4, h5, h6, p, .h1, .h2, .h3, .h4, .h5, .h6, .p {
    display:block;
    margin-top:$text--spacing;
    margin-bottom:$text--spacing;
}
$headings-list: h1 h2 h3 h4 h5 h6 p;
$heading--loop:'';
$i : 0;
@each $heading in $headings-list {
    $ii : 0;
    $i : $i+1;
    @if ($i != 1) {
        $heading--loop: $heading--loop + ', '
    }
    @each $headingg in $headings-list {
        $ii : $ii+1;
        @if ($ii != 1) {
            $heading--loop: $heading--loop + ', '
        }
        $heading--loop: $heading--loop + $heading + ' + ' + $headingg + ', ' + $heading + ' + .' + $headingg + ', .' + $heading + ' + ' + $headingg + ', .' + $heading + ' + .' + $headingg;
    }
}
#{$heading--loop} {
    margin-top:$text--spacing--top;
}
$heading--loop:'';
$i : 0;
@each $heading in $headings-list {
    $ii : 0;
    $i : $i+1;
    @if ($i != 1) {
        $heading--loop: $heading--loop + ', '
    }
    @each $headingg in $headings-list {
        $ii : $ii+1;
        @if ($ii != 1) {
            $heading--loop: $heading--loop + ', '
        }
        $heading--loop: $heading--loop + $heading + ' .' + $headingg;
    }
}
#{$heading--loop} {
    margin-bottom:inherit;
    margin-top:inherit;
}

h1 a, .h1 a,
h2 a, .h2 a,
h3 a, .h3 a,
h4 a, .h4 a,
h5 a, .h5 a,
h6 a, .h6 a {
    font:inherit!important;
    font-weight: inehrit !important;
    line-height:inherit!important;
    text-decoration:inherit!important;
    text-transform:inherit!important;
    color:inherit!important;
    word-spacing:inherit!important;
    letter-spacing:inherit!important;
}

// Paragraph and body text
p, li:not(.cludo-facet__value-list-item), .p {
    font-family: $p--font-family;
    font-size: $p--font-size;
    line-height: $p--line-height;
    font-style: $p--font-style;
    font-weight: $p--font-weight;
    letter-spacing: $p--letter-spacing;
    word-spacing: $p--word-spacing;
    color: $p--color;
}

// Hyperlinks
a {
    font-family: $a--font-family;
    font-size: $a--font-size;
    line-height: $a--line-height;
    font-style: $a--font-style;
    font-weight: $a--font-weight;
    text-transform: $a--text-transform;
    text-decoration: $a--text-decoration;
    letter-spacing: $a--letter-spacing;
    word-spacing: $a--word-spacing;
    color: $a--color;
}

.section {
  .p a:not(.btn), p a:not(.btn) {
      text-decoration: $a--text-decoration !important;
  }
}

ul.check-list, ul.check--list {
	list-style: none;
	padding-left: 0;
}

p + ul.check-list {
	margin-top: -10px !important;
}

ul.check-list > li, ul.check--list > li {
	font-size: 23px;
	// color: $a--color;
	line-height: 2em;
}
ul.check--list > li > strong, ul.check-list > li > strong {
	display: inline-block !important;
	max-width: 85%;
	vertical-align: top;
}
ul.check-list > li:before, ul.check--list > li:before {
	content: '\f00c';
	display: inline-block;
	font-family: 'Font Awesome 5 Free';
	margin-right: 10px;
	font-weight: 900;
	color: $a--color;
	vertical-align: top;
}

/* Weight */
.lighter, .all--lighter, .all--lighter * {font-weight:$lighter!important;}
.light, .all--light, .all--light * {font-weight:$light!important;}
.normal, .all--normal, .all--normal * {font-style:normal;font-weight:$normal!important;}
.normal--weight, .all--normal--weight, .all--normal--weight * {font-weight:$normal!important;}
.medium, .all--medium, .all--medium *, {font-weight:$medium !important;}
.semibold, .all--semibold, .all--semibold *, {font-weight:$semibold!important;}
.bold, .strong, .all--bold, .all--bold *, .all--strong, .all--strong * {font-weight:$bold!important;}
.bolder, .all--bolder, .all--bolder * {font-weight:$bolder!important;}

/* Style */
.italic, .all--italic, .all--italic * {font-style:italic!important;}
.uppercase, .all--uppercase, .all--uppercase * {text-transform:uppercase!important;}
.lowercase, .all--lowercase, .all--lowercase * {text-transform:lowercase!important;}
.normalcase, .all--normalcase, .all--normalcase * {text-transform:none!important;}
.normal--style, .all--normal--style, .all--normal--style * {font-style:normal!important;}

blockquote {
	border-left: 3px solid color('primary');
	margin-left: 0;
	padding-left: 30px;
	background: color('white');
	p {
	    padding: 15px;
	}
}

sup {
	font-size: 50% !important;
	position: relative;
	top: 0px;
	vertical-align: super;
}

@media screen and (max-width: 680px) {
	h1, .h1 {
		font-size: 40px;
		line-height: #{ 50 / 40 }em;
	}
	h2, .h2 {
		font-size: 30px;
		line-height: #{ 40 / 30 }em;
	}
	h3, .h3 {
		font-size: 28px;
		line-height: #{ 40 / 28 }em;
	}
	h4, .h4 {
		font-size: 22px;
		line-height: #{ 34 / 22 }em;
	}
	h5, .h5 {
		font-size: 20px;
		line-height: #{ 28 / 20 }em;
	}
	h6, .h6 {
		font-size: 16px;
		line-height: #{ 22 / 16 }em;
	}
	p, .p {
		font-size: 16px;
		line-height: #{ 32 / 16 }em;
	}
}

::-moz-selection{ background: color('secondary'); color:#fff; text-shadow: none; }
::selection { background:color('secondary'); color:#fff; text-shadow: none; }
