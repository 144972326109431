// main: ../../theme.scss

.background--email {
	background-color: color('grey');
}

.background,
.background--layer {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.blog {
	.blog--listing--cards--post {
		margin-bottom: 80px;
		text-align: left;
	}

	.post-item--header {
		margin: 15px 0;
	}

	.post-item--body {
		font-size: 16px;
		line-height: 1.5em;
		margin: 15px 0;
	}


	.blog--listing--cards--post--more .btn--readmore {
		display: inline-block;
		font-weight: $btn--font-weight;
		line-height: $btn--line-height;
		font-size: $btn--font-size;
		text-transform: $btn--text-transform;
		font-weight: 600 !important;
		font-family: font('reading');
		border: 2px solid color('primary');
		background-color: color('primary');
		color: $btn--color;
		padding: $btn--vertical-padding $btn--horizontal-padding;
		border-radius: $btn--border-radius;
		position: relative;
		overflow: hidden;
		z-index: 1;
		width: auto;
		max-width: 100%;
		text-align: center;
		-moz-box-sizing: border-box !important;
		-webkit-box-sizing: border-box !important;
		box-sizing: border-box !important;
		margin-left: 0px;
		letter-spacing: $btn--letter-spacing;
		-webkit-transition: all 0.2s;
		-moz-transition: all 0.2s;
		transition: all 0.2s;
		vertical-align: middle;
		text-decoration: none;

		&:hover {
			background: transparent !important;
			color: color('primary');

			&:before {
				height: 100% !important;
			}
		}
	}

	.blog--listing--cards--post--share {
		ul.blog--listing--cards--post--share--list {
			vertical-align: middle;
			display: inline-block;
			position: absolute;
			list-style: none;
			padding: 0;
			margin: 0;
			left: -100%;
			background-color: color('white');
			-webkit-transition: left 0.5s;
			-o-transition: left 0.5s;
			transition: left 0.5s;
			top: 50%;
			margin-top: -20px;
			width: 100%;
			background: color('white');
			white-space: nowrap;
			font-size: 0;

			li {
				display: inline-block;
				vertical-align: middle;

				a {
					display: block;
					width: 45px;
					height: 41px;
					line-height: 41px;
					text-align: center;
					font-size: 16px;

					&:hover {
						color: inherit;
					}
				}
			}
		}

		p {
			text-transform: uppercase;
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 1024px) {
	.blog  {
		.post-item--header {
			font-size: 30px;
		}

		.blog--listing--cards--post--share .blog--listing--cards--post--share--list li a {
			margin-top: 10px;
		}
	}
}

@media screen and (min-width: 681px) {
	.blog  .blog--listing--cards--post--share {
		padding-top: 10px;
	}
}

@media screen and (max-width: 680px) {
	.blog  {
		.blog--listing--cards--post--more .btn--readmore {
			width: auto;
			min-width: 0px;
		}

		.blog--listing--cards--post--share {
			padding-top: 8px;
		}

		.blog--listing--cards--post--more {
			overflow: hidden;
		}
	}
}

/** BLOG HEADER **/
.blog--cards--header {
	display: inline-block;
	width: 100%;
	font-size: 0;

	.blog--cards--menu > ul > li {
		display: inline-block;
		position: relative;
		border-bottom: 0;
		font-size: 16px;
		font-weight: 400;
		text-transform: uppercase;
		-webkit-background-clip: padding-box;
		background-clip: padding-box;
		cursor: pointer;
		vertical-align: middle;
		line-height: normal;

		> .menu--name {
			display: inline-block;
			padding: 12px 15px;
			font-size: 16px;
			line-height: 16px;
			display: inline-block;
			padding: 12px 15px;
			color: color('grey');
			font-size: 16px;
			line-height: 16px;
			font-family: font('reading');
			margin-top:0;

			&:after {
				content: "\f107";
				position: relative;
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				margin-left: 0.3em;
			}
		}

		ul {
			display: none;
			position: absolute;
			width: 220px;
			top: 100%;
			right: 0;
			list-style: none;
			z-index: 10;
			text-transform: none;
			background: #f8f8f8;
			padding: 16px;
		}

		&:hover {
			ul {
				display: inline-block;
				a {
					text-decoration: none !important;
				}
			}

			> .menu--name {
				background: #f8f8f8;
			}
		}
	}

	.blog--cards--head--topics {
		ul.menu {
			padding: 0;
			li {
				a {
					display: block;
					font-weight: $normal;
					padding: 12px 20px;
					text-decoration: none;
					color: color('grey');
					font-size: 16px;
					font-family: font('reading');
					transition: all .3s ease-in-out;
				}
				&:hover {
					a {
						background: color('lightgrey');
						font-weight: $bold;
						color: color('primary') !important;
					}
				}
			}
		}
	}

	.blog--cards--head--social ul {
		font-size: 0;
		margin: 0;

		li {
			display: inline-block;
			margin-left: 0;
			margin-right: 5px;
			a {
				text-align: center;
				i {
					font-size: 20px;
					line-height: 40px;
				}
			}
		}
	}
}


@media screen and (min-width: 1025px) {
	.blog--cards--header {
		.blog--cards--title-description {
			width: 55%;
			display: inline-block;
			vertical-align: middle;
		}

		.blog--cards--cats-social-subscribe {
			width: 45%;
			display: inline-block;
			vertical-align: middle;

			.blog--cards--head--social {
				ul {
					li {
						a {
							display: block;
							width: 40px;
							height: 40px;
							line-height: 40px;
							text-align: center;
							color: color('white');
						}
					}
				}
			}
		}

		.blog--cards--title {
			font-size: 42px;
			margin: 0 0 10px 0;
			padding: 0;
			line-height: 1.25em;
		}

		.blog--cards--description {
			font-size: 16px;
		}
	}

	.blog--cards--menu {
		text-align: right;
	}
}
/** MOBILE MENU **/
.blog--cards--mobilemenu {
	background-color: color('blue');
}
.blog--cards--mobilemenu ul {
	margin: 0;
	padding: 0;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--top a:hover {
	cursor: pointer
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--top ul {
	display:inline-block;
	vertical-align:middle;
	list-style: none;
	padding: 0;
	margin: 0;
	white-space:nowrap;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--top ul li {
	display: inline-block;
	font-size: 15px;
	font-weight: bold;
	float: left;
	margin-top: 1px;
	margin-left: 12px;
	vertical-align: middle;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--top ul li:first-child {
	margin-left: 0;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--top ul li a {
	text-transform: uppercase;
	font-size: 14px;
	color: #ffffff;
	text-decoration: none;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--top ul li a .fa {
	display: inline-block;
	font-size: 14px;
	margin-left: 4px;
	margin-top: -4px;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--top .blog--cards--mobilemenu--top--subscribe {
	position:absolute;
	top:50%;
	right:0;
	transform:translateY(-50%);
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--top .blog--cards--mobilemenu--top--subscribe a {
	display: inline-block;
	padding: 4px 28px;
	font-size: 16px;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--top a.active .fa-caret-down:before {
	content: "\f0d8"
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom {
	position: absolute;
	left: 0;
	right: 0;
	background: color('lightgrey');
	z-index: 2;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom ul {
	margin: 0 auto;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom * {
	color: #5d6769;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--item {
	max-height: 0;
	overflow: hidden;
	-webkit-box-shadow:0px 0px 3px 0px rgba(0,0,0,.3);
	-moz-box-shadow:0px 0px 3px 0px rgba(0,0,0,.3);
	box-shadow:0px 0px 3px 0px rgba(0,0,0,.3);
	-webkit-transition: max-height .5s ease-out;
	-moz-transition: max-height .5s ease-out;
	-ms-transition: max-height .5s ease-out;
	-o-transition: max-height .5s ease-out;
	transition: max-height .5s ease-out;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--item .blog--cards--mobilemenu--bottom--item--inner .grid__item {
	text-align:left;
	padding: 10px 0!important;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--categories ul {
	list-style: none;
	text-align: left;
	margin-bottom: 0;
	padding:0px;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--categories ul li {
	font-size: 16px;
	padding: 4px 0;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--categories ul li a {
	text-decoration: none;
	line-height: 1.7em;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--categories ul li a:hover {
	color: #1082c1;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--social ul {
	list-style: none;
	text-align: left;
	padding:0px;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--social ul li {
	display: inline-block;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--social ul li a {
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--social ul li a * {
	color: #ffffff;
}
.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--search ul {
	list-style: none;
	padding: 0 10px;
	text-align: left;
}
@include media-query('palm') {
	.blog--cards--mobilemenu .blog--cards--mobilemenu--top ul li a {
		font-size:12px;
	}
}

/** COLOR SETTINGS **/
.blog--cards--mobilemenu .blog--cards--mobilemenu--top ul li a {color:color('white');}

.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--categories ul li a {color:color('black');}

.blog--cards--mobilemenu .blog--cards--mobilemenu--bottom .blog--cards--mobilemenu--bottom--categories ul li a:hover {color:color('primary');}


/** BLOG INNER **/

.blog--cards--single {
	.scroll--wrapper {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		margin-left: 1.5%;
		margin-right: 1.5%;
		position: relative;
	}

	.scroll--item {
		i {
			font-size: 18px;
		}
	}

	// max-width: 1168px;
	// width: 80%;
	// margin: 0 auto;

	.post--single {
		width: 75%;
		margin: 0 auto;
	}

	.post--single--featured {
		position: relative;
		.post--single--subscribe {
			position: absolute;
			top: 20px;
			right: 20px;
		}
	}

	.post--single {
		padding: 0;
	}

	blockquote {
		background-color: color('white');
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 40px;
		padding-right: 40px;
		font-style: italic;
		border-top: 1px solid color('grey');
		border-bottom: 1px solid color('grey');
		border-right: 1px solid color('grey');
		border-left: 6px solid color('primary');
		margin: 20px 0;

		* {
			margin: 0;
			padding: 0;
		}
	}
}

.bop--subscription--modal {
	width: ( $grid--max-width / 12 ) * 10;
	max-width: 100%;
}

@media screen and (min-width: 681px) and (max-width: 1024px) {
	.blog--cards--single .post--single {
		width: 100%;
	}
}

.blog  .post-single--body a {
	text-decoration: underline;

	&:hover {
		color: color('lightgrey');
	}
}

.blog--cards--single {
	.post-single--body ul {
		list-style: disc;
		margin-bottom: 20px;
		font-size: 18px;

		li {
			line-height: 1.7em;
			margin-bottom: 5px;
		}
	}

	.blog--single--social ul {
		list-style: none;
		margin: 20px 0 0;
		padding: 0;
		i {
			padding-top: 10px;
		}
	}

	.blog--single--author--image {
		width: 80px;
		height: 80px;
		margin: -45px 10px 0;
	}
}

@media screen and (max-width: 680px) {
	.blog--cards--single {
		.blog--single--author--image {
			width: 60px;
			height: 60px;
			margin: -30px 10px 0;
		}

		.post--single {
			width: 100%;
		}
	}

	.blog--single--social {
		display: none;
	}

	.blog--single--mobile--social {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 9;

		ul {
			font-size: 0;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
				vertical-align: middle;

				a {
					display: block;
					color: color('white');
					height: 50px;
					text-align: center;
					line-height: 50px;
					font-size: 16px;
				}
			}
		}
	}
}

@media screen and (min-width: 1025px) {
	.blog--cards--single .blog--single--social {
		text-align: center;
		width: 50px;
		position: absolute;
		top: 0;
		left: 0;
		-webkit-transition: margin-top 1.5s ease-in-out;
		-moz-transition: margin-top 1.5s ease-in-out;
		-ms-transition: margin-top 1.5s ease-in-out;
		-o-transition: margin-top 1.5s ease-in-out;
		transition: margin-top 1.5s ease-in-out;
		-webkit-transition-delay: 0.3s;
		transition-delay: 0.3s;

		ul li {
			&:first-child {
				background-color: #b0b0b0;
				font-size: 14px;
				color: color('white');
				padding: 2px;
				text-align: center;
			}

			a {
				width: 100%;
			}
		}
	}
}

@media screen and (min-width: 681px) {
	.blog--cards--single .blog--single--social ul li a {
		display: block;
		height: 40px;
		color: color('white');
		text-align: center !important;

		.fa {
			line-height: 40px;
			font-size: 20px;
		}
	}

	.blog--single--mobile--social {
		display: none;
	}
}

@media screen and (min-width: 681px) and (max-width: 1024px) {
	.blog--cards--single .blog--single--social {
		position: relative !important;
		margin-top: 0 !important;
		margin-bottom: 34px;
		width: 100%;

		ul li {
			a {
				width: 40px;
			}

			display: inline-block;
			vertical-align: middle;
			margin: 2px;
		}
	}
}

ol.commentlist li.comment.depth-1 {
	margin-bottom: 48px;
	border-bottom: 1px solid color('lightgrey');
}

.comment-body {
	padding-left: 0;
}

.comment {
	.children {
		margin-left: 5%;
	}

	.comment_avatar {
		display: none;
	}
}

.saboxplugin-wrap {
	border: none;
	overflow: visible;
	text-align: center;
}

.blog--author .saboxplugin-wrap {
	margin: 75px 0;
}

.blog--cards--single .saboxplugin-wrap, .blog--author .saboxplugin-wrap {
	margin-top: 100px !important;
	border-top: 1px solid color('lightgrey');
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
	overflow: visible;
}

.saboxplugin-wrap {
	.saboxplugin-web {
		display: none;
	}

	.saboxplugin-gravatar {
		float: none;
		padding: 0;
		margin-top: -80px;
		position: relative;
	}

	.saboxplugin-authorname a {
		&.vcard.author:before {
			content: "About ";
		}

		font-size: 22px;

		span {
			font-size: 22px;
		}
	}

	.saboxplugin-gravatar {
		float: none !important;
		padding: 20px 0 0 0 !important;

		img {
			max-width: 155px !important;
			width: 155px;
			display: inline-block;
			border: 15px solid color('white');
			margin-top: -30px;
			border-radius: 9999px;
		}
	}
}

.blog--author .saboxplugin-wrap .saboxplugin-gravatar img {
	max-width: 200px;
	width: 200px;
}

.saboxplugin-wrap {
	.saboxplugin-socials {
		border-top: 0 !important;
		background-color: color('white') !important;
	}

	.saboxplugin-authorname {
		padding: 0;
		margin: 0 0 20px 0;
	}

	.saboxplugin-desc {
		padding: 0;
		margin: 0;
	}

	.saboxplugin-socials {
		box-shadow: none;
	}
}

/** BLOG COMMENTS **/
#commentform {
	input[type="text"],
	input[type="password"],
	input[type="datetime"],
	input[type="datetime-local"],
	input[type="date"],
	input[type="month"],
	input[type="time"],
	input[type="week"],
	input[type="number"],
	input[type="email"],
	input[type="url"],
	input[type="search"],
	input[type="tel"],
	textarea {
		background-color: #F8F8F8;
		color: color('black');
		border: 1px solid color('grey');
	}
}

.form-submit {
	text-align: left;

	.submit {
		display:inline-block;

		color: $btn--color;
		background-image:none;
		text-align: center;
		font-family: $btn--font-family;
		font-size: $btn--font-size;
		line-height: $btn--line-height;
		font-style: $btn--font-style;
		font-weight: $btn--font-weight;
		text-transform: $btn--text-transform;
		text-decoration: $btn--text-decoration;
		letter-spacing: $btn--letter-spacing;
		word-spacing: $btn--word-spacing;
		padding: 20px 20px 16px 25px;
		border-radius: $btn--border-radius;
		min-width: $btn--min-width;
		width:auto;
		max-width:100% !important;

		border-width:$btn--border-width!important;
		border-style:solid!important;

		box-shadow:none!important;

		background-image:none!important;

		outline:none!important;

		-moz-box-sizing:border-box!important;
		-webkit-box-sizing:border-box!important;
		box-sizing:border-box!important;

		-webkit-transition: all .3s ease-in-out;
		-moz-transition: all .3s ease-in-out;
		-o-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;

		background-color: color('primary');
		color: color('white');
		border-color: color('primary');

		&:hover {
			background-color: #0E396D!important;
			border-color: #0E396D!important;
			color: color('white')!important;
		}
	}
}

.comment-reply-link {
	display: inline-block;
    font-family: font('reading');
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 4px;
	font-size: 18px;
	text-decoration: none;

	&:after {
		content: '\f30b';
		font-family: Fontawesome, 'Font Awesome 5 Free';
		font-weight: 900;
		display: inline-block;
		transform: translateX(0);
		transition: all .3s ease-in-out;
		font-size: 15px;

    }

	&:hover {
		text-decoration: none;
		color: color('secondary');
		&:after {
			transform: translateX(5px);
		}
	}
}

.blog--single--author {
	.grid__item {
		border-top: 1px solid #ddd;
		margin-top: 100px;
		padding-top: 0;

		.blog--single--author--image {
			display: inline-block;
			width: 155px;
			height: 155px;
			border-radius: 9999px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			margin: -80px auto 0 auto;
			border: 15px solid #fff;
		}
	}
}

.blog--single--comments {
	.says {
		display: none;
	}
	.comment-author, .comment-metadata {
		display: inline-block;
	}
	ol {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	.comment-author {
		a {
			font-size: 24px;
			text-decoration: none;
			color: color('black');
		}
	}
	.comment-metadata {
		a {
			text-decoration: none;
			color: color('black');
			font-size: 14px;
		}
	}

	  .depth-1 {
		padding-left: 0%;
		padding-bottom: 5%;
	  }

	  .depth-2 {
		padding-left: 5%;
		padding-top: 5%;
	  }

	  .depth-3 {
		padding-left: 10%;
		padding-top: 5%;
	  }

	  .depth-4 {
		padding-left: 15%;
		padding-top: 5%;
	  }

	  .depth-5 {
		padding-left: 20%;
		padding-top: 5%;
	  }

	  .depth-6 {
		padding-left: 25%;
		padding-top: 5%;
	  }

	  .depth-7 {
		padding-left: 30%;
		padding-top: 5%;
	  }

	  .depth-8 {
		padding-left: 35%;
		padding-top: 5%;
	  }

	  .depth-9 {
		padding-left: 40%;
		padding-top: 5%;
	  }
}

.blog--cards--listing--item--topics,
.blog--cards--single--topics {
	margin: 10px 0 0 0;
    line-height: 1.2em;
    font-size: 16px;
    letter-spacing: 1px;
	text-transform: uppercase;

	a {
		color: color('grey');
		font-weight: bold;
		&:hover {
			color: color('primary');
		}
	}
}
