
/*****************************
  VARIABLES
*****************************/
$grid--column-spacing                            : $grid--column-spacing;
$grid--width                                     : $grid--width;

/* HELLO BAR */
$header_hello_BackgroundColor                   : color('primary');
$header_hello_FontFamily                        : font('reading');
$header_hello_FontSize                          : 14px;
$header_hello_MobileFontSize                    : 14px;
$header_hello_FontWeight                        : $normal;
$header_hello_FontColor                         : color('white');
$header_hello_CloseColor                        : color('white');
$header_hello_CloseHoverColor                   : color('lightgrey');

/* HEADER GENERAL */
$header_BackgroundColor                         : color('white');
$header_BoxShadow                               : 0px 0px 30px 0px rgba(66,66,66,.1);
$header_TransparentBoxShadow                    : 0px 0px 30px 0px rgba(66,66,66,0);
$header_TransparentDividerColor                 : color("white");
$header_HeaderAnchorHeight                      : 136px;
$header_MainLogoPadding                         : 10px 0;

/* HEADER TOP */
$header_top_BackgroundColor                     : color('white');
$header_top_TransparentBackgroundColor          : transparent;
$header_top_ContentBackgroundColor              : color('primary');
$header_top_TransparentContentBackgroundColor   : rgba(255,255,255,.1);
$header_top_ContentBorderRadius                 : 6px;

$header_top_FontFamily                          : font('reading');
$header_top_FontSize                            : 14px;
$header_top_LineHeight                          : #{ 19px / $header_top_FontSize }em;
$header_top_FontStyle                           : undefined;
$header_top_FontWeight                          : $bold;
$header_top_TextTransform                       : none;
$header_top_TextDecoration                      : none;
$header_top_LetterSpacing                       : undefined;
$header_top_WordSpacing                         : undefined;
$header_top_FontColor                           : color('white');
$header_top_HoverFontColor                      : color('white');
$header_top_TransparentFontColor                : color('secondary');
$header_top_HoverTransparentFontColor           : color('primary');

/* HEADER SEARCH */
$header_search_BackgroundColor                  : color('white');
$header_search_InputBackgroundColor             : color('lightgrey');
$header_search_InputFontFamily                  : font('reading');
$header_search_InputFontSize                    : 14px;
$header_search_InputFontWeight                  : $semibold;
$header_search_InputFontColor                   : color('grey');

$header_search_IconColor                        : color('primary');
$header_search_HoverIconColor                   : color('primary');
$header_search_TransparentIconColor             : color('white');
$header_search_TransparentHoverIconColor        : color('white');

/* HEADER MAIN */
$header_main_CTAColor                           : color('secondary');
$header_main_HoverCTAColor                      : colorShade(color('primary'));
$header_main_TransparentCTAColor                : rgba(255,255,255,.5);
$header_main_TransparentCTAFontColor            : color('white');
$header_main_TransparentHoverCTAColor           : color('grey');
$header_main_TransparentHoverCTAFontColor       : color('white');

$header_main_FontFamily                         : font('reading');
$header_main_FontSize                           : 15px;
$header_main_FontWeight                         : $bold;
$header_main_FontColor                          : color('grey');
$header_main_HoverFontColor                     : color('primary');
$header_main_TextTransform                      : none;
$header_main_LetterSpacing                      : 0;

$header_main_BorderColor                        : color('white');
$header_main_HoverBorderColor                   : color('primary');

$header_subMenu_FontFamily                      : font('reading');
$header_subMenu_FontSize                        : 15px;
$header_subMenu_LineHeight                      : 1.3em;
$header_subMenu_FontColor                       : color('grey');
$header_subMenu_HoverFontColor                  : color('primary');
$header_subMenu_HoverBorderColor                : color('primary');

$header_DropdownBackgroundColor                 : color('white');
$header_DropdownBoxShadow                       : 0px 15px 20px 0px rgba(66,66,66,.07);
$header_DropdownBorderRadius                    : 0;
$header_DropdownBoxShadowThirdLevel             : 0px 15px 20px 0px rgba(66,66,66,.07);

$header_megamenuHeader_LabelFontFamily          : font('heading');
$header_megamenuHeader_LabelFontSize            : 15px;
$header_megamenuHeader_LabelLineHeight          : #{ 20px / $header_megamenuHeader_LabelFontSize }em;
$header_megamenuHeader_LabelFontWeight          : $bold;
$header_megamenuHeader_LabelFontColor           : color('grey');
$header_megamenuHeader_LabelHoverFontColor      : color('primary');
$header_megamenuHeader_LabelTextTransform       : none;
$header_megamenuHeader_LabelLetterSpacing       : 0;
$header_megamenuHeader_LabelBorderColor         : color('mediumgrey');

$header_megamenuHeader_DescriptionFontFamily    : font('reading');
$header_megamenuHeader_DescriptionFontSize      : 13px;
$header_megamenuHeader_DescriptionLineHeight    : #{ 20px / $header_megamenuHeader_LabelFontSize }em;
$header_megamenuHeader_DescriptionFontWeight    : $normal;
$header_megamenuHeader_DescriptionFontColor     : color('grey');
$header_megamenuHeader_DescriptionTextTransform : none;
$header_megamenuHeader_DescriptionLetterSpacing : 0;

$header_megamenu_CTALabel_FontFamily            : font('heading');
$header_megamenu_CTALabel_FontSize              : 17px;
$header_megamenu_CTALabel_LineHeight            : #{ 20px / $header_megamenuHeader_DescriptionFontSize }em;
$header_megamenu_CTALabel_FontWeight            : $semibold;
$header_megamenu_CTALabel_FontColor             : color('primary');
$header_megamenu_CTALabel_TextTransform         : undefined;
$header_megamenu_CTALabel_LetterSpacing         : -.19px;

$header_megamenu_ctaContainer_BackgroundColor   : color('lightgrey');
$header_megamenu_ctaContainer_BorderRadius      : 0;

/* HEADER TRIGGER ICON */
$header_menuTrigger_Color                       : color('primary');

/* MOBILE NAV */
$mobile_BackgroundColor                         : color('white');
$mobile_BorderColor                             : color('lightgrey');

$mobile_depth0Label_FontFamily                  : font('reading');
$mobile_depth0Label_FontSize                    : 15px;
$mobile_depth0Label_LineHeight                  : #{ 20px / $mobile_depth0Label_FontSize }em;
$mobile_depth0Label_FontWeight                  : $normal;
$mobile_depth0Label_FontColor                   : color('grey');
$mobile_depth0Label_HoverFontColor              : color('primary');
$mobile_depth0Label_TextTransform               : undefined;
$mobile_depth0Label_LetterSpacing               : undefined;

$mobile_backLabel_FontFamily                    : font('reading');
$mobile_backLabel_FontSize                      : 15px;
$mobile_backLabel_LineHeight                    : #{ 20px / $mobile_backLabel_FontSize }em;
$mobile_backLabel_FontWeight                    : $bold;
$mobile_backLabel_FontColor                     : color('primary');
$mobile_backLabel_HoverFontColor                : color('primary');
$mobile_backLabel_TextTransform                 : undefined;
$mobile_backLabel_LetterSpacing                 : undefined;

$mobile_label_FontFamily                        : font('reading');
$mobile_label_FontSize                          : 15px;
$mobile_label_LineHeight                        : #{ 20px / $mobile_label_FontSize }em;
$mobile_label_FontWeight                        : $normal;
$mobile_label_FontColor                         : color('grey');
$mobile_label_HoverFontColor                    : color('primary');
$mobile_label_TextTransform                     : undefined;
$mobile_label_LetterSpacing                     : undefined;
$mobile_label_TextDecoration                    : none;



/*****************************
	ACCESSIBILITY
*****************************/
.skip-to-content-link {
	background: color('white');
	height: auto;
	left: 50%;
	padding: 15px 30px;
	position: absolute;
	transform: translate(-50%, -100%);
	transition: transform 0.3s;
	z-index: 0;
	font-size: 16px;
	font-family: font('reading');
}
.skip-to-content-link:focus {
	z-index: 99999;
	transform: translate(-50%, 0%);
}

/*****************************
	HEADER
*****************************/
header {
	display:block;
	position:absolute;
	top:0;
	left:0;
	z-index:100;
	width:100%;
	background: $header_BackgroundColor;
	-webkit-box-shadow:$header_BoxShadow;
	-moz-box-shadow:$header_BoxShadow;
	box-shadow:$header_BoxShadow;
	transition: box-shadow .3s ease-in-out, top .3s linear, background .3s ease-in-out;
}
.header--body {
	display:block;
	position:relative;
	left:0;
	width:100%;
	padding-left: 0;
	padding-right: 0;
}
.header--anchor {
	display:block;
	width:100%;
	height: $header_HeaderAnchorHeight;
	@include media-query('portable') {
		height: 80px;
	}
}
body.admin-bar header {
	top: 32px;
}

/*****************************
	HELLO BAR
*****************************/
.header--hello {
	display:none;
	background: $header_hello_BackgroundColor;
	max-height: 100px;
	opacity: 1;
}
.header--hello--content {
	display:inline-block;
	position:relative;
	padding:6px 40px;
}
.header--hello--content--body {
	display:inline-block;
	vertical-align:middle;
	padding:7px 10px;
}
.header--hello--content--body,
.header--hello--content--body .p,
.header--hello--content--cta .btn .btn--inner,
.header--hello--content--cta .all--btn .hs-cta-wrapper a {
	font-size: $header_hello_FontSize !important;
	font-family: $header_hello_FontFamily !important;
	color: $header_hello_FontColor;
	font-weight: $header_hello_FontWeight;
}
.header--hello--content--cta {
	display:inline-block;
	vertical-align:middle;
	padding:0px 10px;
}
.header--hello--content--cta .btn {
	padding: 12px 28px !important;
}
.header--hello--close {
	position:absolute;
	right:20px;
	top:50%;
	height:18px;
	width:18px;
	font-size:19px;
	z-index:10;
	margin-top:-9px;
}
.header--hello--close--icon {
	display:block;
	position:absolute;
	top:0;
	left:0;
	width:18px;
	height:18px;
}
.header--hello--close--icon path {
	fill: $header_hello_CloseColor;
	@include transition(fill .3s ease-in-out);
}
.header--hello--close:hover {
	cursor:pointer;
}
.header--hello--close:hover .header--hello--close--icon path {
	fill: $header_hello_CloseHoverColor;
}
@include media-query('palm') {
	.header--hello--content--body,
	.header--hello--content--body p,
	.header--hello--content--cta .btn .btn--inner,
	.header--hello--content--cta .all--btn .hs-cta-wrapper a {
		font-size: $header_hello_MobileFontSize;
	}
	.header--hello--close {
		top:30px;
	}
}

/****************************
	HEADER TOP SEARCH
****************************/
.header--main--search {
	display:inline-block;
	position:relative;
	opacity: 1;
	transition: opacity .3s ease-in-out;
	display: inline-block;
	vertical-align: top;
	background-color: color('primary');
	margin: 0;
	padding-left: 30px;
	list-style: none;
	position: relative;
	&:before {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		left: -100%;
		top: 0;
		background-color: color('white');
	}
}
.header--main--search:last-child {
	margin-right:15px;
}
.header--main--search--input {
	display:inline-block;
	width:352px;
	z-index:2;
	background: transparent;
}
.header--main--search--input--container {
	padding: 0;
	line-height: 30px;
	border-radius: $input--border-radius;
	&:before {
		position: absolute;
		left: 0;
		width: auto;
		border-top: 32px solid transparent;
		height:1px;
		border-right: 0 solid transparent;
		border-bottom: 0 solid transparent;
		border-left: 32px solid color('white');
		content: "";
		z-index: 5;
	}
}
.header--main--search--input--input,
.header--main--search--input--input.cludo-input-form__input {
	display:block;
	border:0px!important;
	border-radius: 0 !important;
	line-height:32px !important;
	height:32px !important;
	box-shadow:none!important;
	width:100%;
	padding: 0 30px 0 40px !important;
	position: relative;
	font-family: $header_search_InputFontFamily !important;
	font-size: $header_search_InputFontSize !important;
	font-weight: $header_search_InputFontWeight !important;
	color: $header_search_InputFontColor !important;
	background: $header_search_InputBackgroundColor !important;
	margin-bottom: 0 !important;
	border: 0 !important;
	height: auto !important;
}
.header--main--search--input--container {
	.cludo-input-form__search-button::before {
		position: relative !important;
		top: 0 !important;
		font-size: 13px !important;
	}
	.cludo-input-form__search-button {
		top: 50% !important;
		right: 0 !important;
	}
}
.header--main--search--input--input::placeholder {
	color: $header_search_InputFontColor !important;
	font-size: 14px !important;
}
.header--main--search--search {
	display:block;
	position:relative;
	line-height:32px;
	background:transparent;
	text-align:center;
	z-index:2;
	padding-left:15px;
	padding-right:10px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
}
.header--main--search--search--icon {
	// position: absolute;
	// top: 0;
	// right: 10px;
	// display:block!important;
	// line-height:32px!important;
	font-size:13px;
	color: $header_search_IconColor;
	transition: color .3s ease-in-out;
	vertical-align: middle !important;
}
.header--fixed .header--main--search--input {
	background: $header_search_BackgroundColor;
}
@include media-query('portable') {
	.header--main--search--trigger {
		line-height: 40px;
		margin-top: 14px;
		margin-bottom: 12px;
		padding-right:20px;
	}
}

/*****************************
	HEADER TOP
*****************************/
.header--top {
	background:transparent;
	text-align: right;
	display: block;
	overflow: visible;
	background: $header_top_BackgroundColor;
	transform: translateY(0);
	max-height: 100px;
	opacity: 1;
	border-top: 3px solid color('primary');
	@include transition(all .3s ease-in-out);
	position: relative;
	z-index: 7;
}
.header--top--nav {
	position: relative;
	text-align: right;
	background-color: color('white');
	display:inline-block;
	cursor: pointer;
	&:before {
		position: absolute;
		content: "";
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: color('lightgrey');
	}
}
.header--top--nav--container {
	display: inline-block;
	background: $header_top_ContentBackgroundColor;
	padding: 0px 15px;
	padding-left: 30px;
	display: inline-block;
	vertical-align: top;
	margin: 0;
	position: relative;
	border-bottom-right-radius: 2px;
	&:before {
		position: absolute;
		left: 0px;
		width: auto;
		border-top: 32px solid transparent;
		height:0px;
		border-right: 0 solid transparent;
		border-bottom: 0 solid transparent;
		border-left: 32px solid color('lightgrey');
		content: "";
	}
}
.header--top--nav .header--top--nav--item ul.sub-menu {
	z-index: 99;
	list-style: none;
	display:none;
	position: absolute;
	top: 100%;
	left: 0;
	width: 200px;
	background: color('primary');
	text-align: left;
	padding: 7px 15px;
	li {
		color: color('white');
		font-size: 14px;
	}
}
.header--top--nav .header--top--nav--item.header--top--nav--item--parent {
	position: relative;
	margin-right: 5px;
	svg {
		// content: '\f0d7';
		display: block;
		position: absolute;
		right: 2px;
		top: 50%;
		transform: translateY(-50%);
		font-family: font('fontawesome');
		font-size: 13px;
		font-weight: $bolder;
		color: color('white');
	}
	ul.sub-menu {
		background: color('primary');
		li a {
			color: color('white');
			text-decoration: none;
			transition: all .3s ease-in-out;
		}
	}
	&:hover,
	&:focus-within {
		ul.sub-menu {
			display: block;
			li a:hover {
				text-decoration: underline;
			}
		}
	}
}
.header--top--nav .header--top--nav--item {
	display: inline-block;
}
.header--top--nav .header--top--nav--item > .header--top--nav--item--label {
	display:block;
	position:relative;
	text-decoration: $header_top_TextDecoration;
	font-family: $header_top_FontFamily;
	font-weight: $header_top_FontWeight;
	font-size: $header_top_FontSize !important;
	line-height: $header_top_LineHeight;
	font-style: $header_top_FontStyle;
	text-transform: $header_top_TextTransform;
	letter-spacing: $header_top_LetterSpacing;
	word-spacing: $header_top_WordSpacing;
	color: $header_top_FontColor;
	transition: color .3s ease-in-out;
	padding: 6px 15px 7px;
}
.header--top--nav .header--top--nav--item .header--top--nav--item--label .header--top--nav--item--icon {
	display: inline-block;
	margin-right: 8px;
}
.header--top--nav .header--top--nav--item .header--top--nav--item--label .header--top--nav--item--inner {
	display: inline-block;
}
.header--top--nav .header--top--nav--item .header--top--nav--item--label .header--top--nav--item--label--inner {
	transition: color .3s ease-in-out;
	display: block;
}
.header--top--nav .header--top--nav--item:hover .header--top--nav--item--label .header--top--nav--item--label--inner {
	text-decoration: none;
	color: $header_top_HoverFontColor;
}
.header--top--nav
.header--top--nav--item.header--top--nav--item--parent:after {
  color: #ffffff;
  content: "\f0d7";
  display: block;
  font-family: 'Font Awesome 5 Free';
  font-size: 13px;
  font-weight: 900;
  position: absolute;
  right: 2px;
  top: 48%;
  transform: translateY(-50%);
}

/*****************************
	HEADER TOP TRANSPARENT
*****************************/
body.header--transparent .header--top {
	background:  $header_top_TransparentBackgroundColor;
}
body.header--transparent .header--top--nav--container {
	background:  $header_top_TransparentContentBackgroundColor;
}
body.header--transparent .header--top--nav .header--top--nav--item > .header--top--nav--item--label {
	color:  $header_top_TransparentFontColor;
}
body.header--transparent .header--top--nav .header--top--nav--item:hover .header--top--nav--item--label .header--top--nav--item--label--inner {
	color: $header_top_HoverTransparentFontColor;
}

/*****************************
	HEADER MAIN
*****************************/
.header--main {
	display:block;
	position:relative;
	text-align:right;
}

/*****************************
	HEADER MAIN LOGO
*****************************/
.header--main .header--main--logo {
	display:inline-block;
	float:none;
	position:relative;
	z-index:6;
	margin-top:34px;
	margin-bottom:38px;
	text-align: left;
	padding-right:0;
}
.header--main .header--main--logo > img {
	display: inline-block;
	width:auto;
	height: 70px;
	transform: translateY(-25%);
}
.header--fixed .header--main .header--main--logo > img {
	transform: translateY(0);
}
@include media-query('portable') {
	.header--main .header--main--logo > img {
		transform: translateY(0);
	}
}
.header--main .header--main--logo--default,
.header--main .header--main--logo--white {
	display:block;
	transition: opacity .3s ease-in-out;
	padding: $header_MainLogoPadding;
}
.header--main .header--main--logo--default {
	display:block;
	position:relative;
	z-index:6;
	opacity:1;
	filter:alpha(opacity=100);
}
.header--main .header--main--logo--white {
	display:block;
	position:absolute;
	top:0;
	left:0;
	opacity:0;
	filter:alpha(opacity=0);
}

/*****************************
	HEADER MAIN
*****************************/
.header--main--nav--wrapper {
	display:inline-block;
	float:none;
}
.header--main--secondarynav--wrapper {
	display:inline-block;
	float:none;
}
.header--main--nav {
	display:inline-block;
	float:none;
	margin-left:0px;
	opacity:1;
	filter:alpha(opacity=100);
	transition: opacity .3s ease-in-out;
}
.header--main--secondarynav {
	display:inline-block;
	float:none;
	margin-left:0px;
}

/*****************************
	HEADER MAIN NAV
*****************************/
.header--main--nav .header--main--nav--item,
.header--main--secondarynav .header--main--secondarynav--item {
	display:inline-block;
	vertical-align:middle;
	position:relative;
	width:auto;
	margin-left:0px;
	font-size: $header_main_FontSize;
	line-height:1.35em;
}
.header--main--nav .header--main--nav--item.header--main--nav--item--megamenu--parent {
	position:static;
}
.header--main--nav .header--main--nav--item > a {
	letter-spacing: $header_main_LetterSpacing;
	font-family: $header_main_FontFamily!important;
	font-weight: $header_main_FontWeight;
	text-transform: $header_main_TextTransform;
	font-size: $header_main_FontSize;
	color: $header_main_FontColor;
}
.header--main--nav .header--main--nav--item:hover > a,
.header--main--nav .header--main--nav--item:hover > span {
	color: $header_main_HoverFontColor;
}
.header--main--nav .header--main--nav--item:first-child,
.header--main--secondarynav .header--main--secondarynav--item:first-child {
	margin-left:0px;
}
.header--main--nav .header--main--nav--item > .header--main--nav--item--label,
.header--main--secondarynav .header--main--secondarynav--item > .header--main--secondarynav--item--label,
.header--main--nav .header--megamenu--body a,
.header--main--nav .header--main--nav--nestedmenu a {
	display:block;
	position:relative;
	text-decoration:none;
	font-family: $header_subMenu_FontFamily;
	font-size:  $header_subMenu_FontSize;
	line-height: $header_subMenu_LineHeight;
	color:  $header_subMenu_FontColor;
	transition: color .3s ease-in-out;
}
.header--main--nav .header--main--nav--item > .header--main--nav--item--label,
.header--main--secondarynav .header--main--secondarynav--item > .header--main--secondarynav--item--label,
.header--main--nav .header--megamenu--body a,
.header--main--nav .header--main--nav--nestedmenu a {
	color:  $header_subMenu_FontColor;

}
.header--main--nav .header--megamenu--body a,
.header--main--nav .header--main--nav--nestedmenu a {
	font-weight: $normal;
}
.header--main--nav > .header--main--nav--item > .header--main--nav--item--label,
.header--main--secondarynav > .header--main--secondarynav--item > .header--main--secondarynav--item--label {
	padding:0px 20px;
	color: $header_main_FontColor;
	font-size: $header_main_FontSize;
	font-weight:$header_main_FontWeight;
	text-transform: $header_main_TextTransform;
	letter-spacing: $header_main_LetterSpacing;
	padding:36px 18px;
	line-height:20px;
	transition: color .3s ease-in-out;
	position: relative;
	z-index: 6;
	cursor: default;
}
.header--main--nav > .header--main--nav--item > a.header--main--nav--item--label {
	cursor: pointer;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--item--label {
	padding-right:33px;
}
.header--main--nav > .header--main--nav--item > .header--main--nav--item--label > .header--main--nav--item--label--inner,
.header--main--secondarynav > .header--main--secondarynav--item > .header--main--secondarynav--item--label > .header--main--secondarynav--item--label--inner {
	position:relative;
}
.header--main--nav > .header--main--nav--item > .header--main--nav--item--label > .header--main--nav--item--label--inner:before,
.header--main--secondarynav > .header--main--secondarynav--item > .header--main--secondarynav--item--label > .header--main--secondarynav--item--label--inner:before {
	content:"";
	display:block;
	position:absolute;
	top:100%;
	background: $header_main_BorderColor;
	border-radius:2px;
	height:2px;
	left:-3px;
	right:-3px;
	margin-top:10px;
	opacity:0;
	filter:alpha(opacity=0);
	transition: opacity .3s ease-in-out;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--item--label > .header--main--nav--item--label--inner:before {
	right:-20px;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--item--label > .header--main--nav--item--label--inner > .header--main--nav--item--label--parent--indicator {
	display: inline-block;
	position:absolute;
	left: 100%;
	margin-left:7px;
	line-height: 20px;
	width: 13px;
	height: 13px;
	svg {
		width: 13px;
		height: 13px;
	}
}

/*****************************
HEADER MAIN NESTED MENU
*****************************/
.hs-menu-wrapper ul {
	list-style: none;
	padding: 0px;
	margin: 0px;
	margin-top: 0px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu {
	display:none;
	position:absolute;
	top:100%;
	left:50%;
	z-index:3;
	margin-top:0px;
	margin-left:-120px;
	border-radius: 5px;
	overflow:visible;
	&:before {
		content: '';
		position: absolute;
		display: block;
		top: -126px;
		width: 500%;
		height: 130px;
		left: -275%;
		background: color('white');
		z-index: 6;
		box-shadow: 0 0 30px 0 rgba(66, 66, 66, .1);
	}
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu > .hs-menu-wrapper {
	display:block;
	text-align:left;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu > .hs-menu-wrapper ul {
	display:none;
	position:absolute;
	top:0;
	left:100%;
	list-style:none;
	width:395px;
	background: $header_DropdownBackgroundColor;
	z-index:2;
	border: 1px solid  $header_DropdownBackgroundColor;
	margin-left:-10px;
	padding:28px 0px;
	box-shadow: $header_DropdownBoxShadow;
	border-radius: $header_DropdownBorderRadius;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu > .hs-menu-wrapper ul ul {
	margin-left:0px;
	margin-top:-29px;
	border-top-left-radius: 0;
	border-color: transparent;
	background: color('lightgrey');
	box-shadow: $header_DropdownBoxShadowThirdLevel;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu > .hs-menu-wrapper li {
	display:block;
	position:relative;
	z-index:2;
	font-size:16px;
	line-height:1.35em;
	background:transparent;
	margin-top:4px;
	transition: background .3s ease-in-out;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu > .hs-menu-wrapper li:first-child {
	margin-top:0px;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu > .hs-menu-wrapper li.hs-item-has-children > a {
	padding-right:45px;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu > .hs-menu-wrapper li.hs-item-has-children > a:after {
	content:"\f054";
	// content:"";
	// background: url('https://f.hubspotusercontent40.net/hubfs/298890/assets/images/angle-right.svg') no-repeat center / 5px 10px;
	display:block;
	position:absolute;
	font-family: font("fontawesome");
	font-weight:600;
	line-height: 20px;
	font-size: 11px;
	right: 30px;
	top: 50%;
	margin-top: -5px;
	width: 14px;
	height: 14px;
}
.header--main--nav > .header--main--nav--item > .header--main--nav--nestedmenu > .hs-menu-wrapper li:hover > a,
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu > .hs-menu-wrapper li:hover > a {
	color: $header_subMenu_HoverFontColor;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu > .hs-menu-wrapper li:hover > ul {
	display:block;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu > .hs-menu-wrapper a {
	display:block;
	padding:9px 36px;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu > .hs-menu-wrapper > ul {
	display:block;
	position:relative;
	top:auto;
	left:auto;
	margin-left:0px;
	border:0;
}

/*****************************
HEADER MAIN MEGAMENU
*****************************/
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--megamenu {
	display:block;
	position:absolute;
	left:50%;
	transform: translateX(-50%);
	height:0px;
	width:auto;
	margin-top:0;
	overflow:hidden;
	z-index:5;
	border-radius: $header_DropdownBorderRadius;
	&:before {
		content: '';
		position: absolute;
		display: block;
		top: -126px;
		width: 500%;
		height: 130px;
		left: -275%;
		background: color('white');
		z-index: 6;
		box-shadow: 0 0 30px 0 rgba(66, 66, 66, .1);
	}
}
.header--main--nav--megamenu .header--megamenu--wrapper {
	display:block;
	width: #{ round( $grid--width / ( ( 100 - $grid--column-spacing ) / 100 ) ) };
	max-width:100%;
	margin-left:auto;
	margin-right:auto;
	background: $header_DropdownBackgroundColor;
	border-radius: $header_DropdownBorderRadius;
	box-shadow: $header_DropdownBoxShadow;
	overflow:hidden;
}
.header--main--nav--megamenu.browser--width .header--megamenu--wrapper {
	width: 100% !important;
	max-width:98%;
	margin: auto !important;
}
.header--main--nav--megamenu.narrow .header--megamenu--wrapper {
	width: #{ round($grid--width / ( ( 100 - $grid--column-spacing ) / 100 ) )  - 208 };
	max-width:100%;
	margin: auto !important;
}
.header--main--nav--megamenu .header--megamenu--body {
	display:block;
	width:100%;
	text-align:left;
	padding: 0 30px;
}
.header--main--nav--megamenu .header--megamenu--body--groups--wrapper {
	display:flex;
	flex-flow: row;
	width:100%;
	text-align:left;
	font-size:0px;
}
.header--main--nav--megamenu .header--megamenu--body--group {
	display:inline-block;
	vertical-align:top;
	width:25%;
	padding:30px;
	position: relative;
}
.header--main--nav--megamenu .header--megamenu--body--group.has--divider:after {
	content: '';
	position: absolute;
	right: 0;
	top: 30px;
	height: #{ calc(100% - 60px) };
	width: 1px;
	background: color('lightgrey');
}
.header--main--nav--megamenu .header--megamenu--body--group .header--megamenu--body--group--background {
	position: absolute;
	top:-500px;
	right: 0;
	bottom: -500%;
	left: 0;
	z-index: 1;
}
.header--main--nav--megamenu .header--megamenu--body--group:after {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 1px;
	background: #DEDEDE;
	right: 0;
}
.header--main--nav--megamenu .header--megamenu--body--group:last-child:after {
	display:none;
}
.header--main--nav--megamenu .header--megamenu--group--wrapper {
	display:block;
	width:100%;
	position: relative;
	z-index: 3;
}
.header--main--nav--megamenu .header--megamenu--link--block--wrapper {
	padding: 15px 0;
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content: space-between;
}
.header--main--nav--megamenu .header--megamenu--link--block--wrapper .header--megamenu--link--block--icon--wrapper {
	flex: 0 0 50px;
	padding-right: 10px;
}
.header--main--nav--megamenu .header--megamenu--link--block--wrapper .header--megamenu--link--block--icon--wrapper img {
	width: 100%;
	height: auto;
}
.header--main--nav--megamenu .header--megamenu--link--block--wrapper .header--megamenu--link--block--content {
	flex: 1;
}
.header--main--nav--megamenu .header--megamenu--group--header {
	display:block;
	width:100%;
	text-decoration:none;
	overflow:visible;
	padding-bottom:8px;
	border-bottom: 1px solid $header_megamenuHeader_LabelBorderColor;
}
.header--main--nav--megamenu .header--megamenu--group--header--label {
	display:block;
	position:relative;
	font-family: $header_megamenuHeader_LabelFontFamily;
	font-size:  $header_megamenuHeader_LabelFontSize;
	line-height: $header_megamenuHeader_LabelLineHeight;
	font-weight: $header_megamenuHeader_LabelFontWeight;
	color: $header_megamenuHeader_LabelFontColor;
	text-transform: $header_megamenuHeader_LabelTextTransform;
	letter-spacing: $header_megamenuHeader_LabelLetterSpacing;
	@include transition(color .3s ease-in-out);
}
.header--main--nav--megamenu a.header--megamenu--group--header .header--megamenu--group--header--label {
	color: color('primary');
}
.header--main--nav--megamenu .header--megamenu--group--header--label--inner {
	position:relative;
	overflow:visible;
}
.header--main--nav--megamenu a.header--megamenu--group--header .header--megamenu--group--header--label--inner:after {
	/* content:"\f105"; */
	position:relative;
	font-family: font('fontawesome');
	font-weight:600;
	left:0px;
	margin-left:9px;
	font-size: 12px;
	vertical-align:bottom;
	transition: left .3s ease-in-out;
}
.header--main--nav--megamenu .header--megamenu--group--header .header--megamenu--group--header--label--inner .header--megamenu--group--header--label--parent--indicator {
	display: inline-block;
	position:relative;
	left:0px;
	margin-left:6px;
	margin-top: 5px;
	vertical-align:top;
	width: 5px;
	height: 5px;
	transition: left .3s ease-in-out;
}
.header--main--nav--megamenu .header--megamenu--group--header .header--megamenu--group--header--label--inner .header--megamenu--group--header--label--parent--indicator svg {
	display: block;
}
.header--main--nav--megamenu a.header--megamenu--group--header:hover .header--megamenu--group--header--label {
	color: $header_megamenuHeader_LabelHoverFontColor;
}
.header--main--nav--megamenu a.header--megamenu--group--header:hover .header--megamenu--group--header--label--inner:after {
	left:4px;
}
.header--main--nav--megamenu a.header--megamenu--group--header:hover .header--megamenu--group--header--label--inner .header--megamenu--group--header--label--parent--indicator {
	left:4px;
}
.header--main--nav--megamenu .header--megamenu--group--header--description {
	display:block;
	font-family: $header_megamenuHeader_DescriptionFontFamily;
	font-size: $header_megamenuHeader_DescriptionFontSize;
	font-weight: $header_megamenuHeader_DescriptionFontWeight;
	text-transform: $header_megamenuHeader_DescriptionTextTransform;
	letter-spacing: $header_megamenuHeader_DescriptionLetterSpacing;
	line-height: $header_megamenuHeader_DescriptionLineHeight;
	margin-top:10px;
	color: $header_megamenuHeader_DescriptionFontColor;
}
.header--main--nav--megamenu .header--megamenu--group--nav {
	display:block;
	margin-top:0;
}
.header--main--nav--megamenu .header--megamenu--group--nav ul {
	display:block;
	padding:0px;
	margin:0px;
	list-style:none;
	padding-top: 10px;
}
.header--main--nav--megamenu .header--megamenu--group--nav ul li {
	display:block!important;
	margin:0px;
	padding:5px 0px;
	line-height:  $header_subMenu_LineHeight;
}
.header--main--nav--megamenu .header--megamenu--group--nav ul a {
	display:inline-block;
	position:relative;
	font-size: $header_subMenu_FontSize;
	line-height: $header_subMenu_LineHeight;
	color: $header_subMenu_FontColor;
	border-bottom: 1px solid transparent;
	transition: all .3s ease-in-out;
}
.header--main--nav--megamenu .header--megamenu--group--nav ul li.active > a,
.header--main--nav--megamenu .header--megamenu--group--nav ul a:hover {
	color: $header_subMenu_HoverFontColor;
	border-bottom: 1px solid  $header_subMenu_HoverBorderColor;
}
.header--main--nav--megamenu .header--megamenu--body--group.all--color--white  a,
.header--main--nav--megamenu .header--megamenu--body--group.all--color--white  .header--megamenu--group--header--label,
.header--main--nav--megamenu .header--megamenu--body--group.all--color--white  .header--megamenu--group--header--description {
	color:color('white')!important;
}
.header--main--nav--megamenu .header--megamenu--body--group.all--color--white .header--megamenu--group--header {
	border-bottom: 1px solid color('white') !important;
}
.header--megamenu--body--group.header--megamenu--body--group--columns--2 .hs-menu-wrapper > ul {
	columns:2;
	column-gap:30px;
}
.header--megamenu--body--group.header--megamenu--body--group--columns--3 .hs-menu-wrapper > ul {
	columns:3;
	column-gap:30px;
}
.header--megamenu--body--group.header--megamenu--body--group--columns--4 .hs-menu-wrapper > ul {
	columns:4;
	column-gap:30px;
}
.header--megamenu--body--group.header--megamenu--body--group--columns--5 .hs-menu-wrapper > ul {
	columns:5;
	column-gap:30px;
}
.header--main--nav--megamenu .header--megamenu--cta--wrapper {
	display:block;
	padding:0px 20px 20px;
}
.header--main--nav--megamenu .header--megamenu--cta {
	display:flex;
	width:100%;
	flex-direction:row;
	padding:18px;
	justify-content:center;
	align-items:center;
	background:  $header_megamenu_ctaContainer_BackgroundColor;
	-webkit-border-radius:  $header_megamenu_ctaContainer_BorderRadius;
	-moz-border-radius:  $header_megamenu_ctaContainer_BorderRadius;
	border-radius:  $header_megamenu_ctaContainer_BorderRadius;
	margin: 0 15px 0 0;
}
.header--main--nav--megamenu .header--megamenu--cta--label {
	padding:10px;
	flex-grow: auto;
	font-family: $header_megamenu_CTALabel_FontFamily ;
	font-weight: $header_megamenu_CTALabel_FontWeight;
	color: $header_megamenu_CTALabel_FontColor;
	font-size: $header_megamenu_CTALabel_FontSize;
	line-height: $header_megamenu_CTALabel_LineHeight;
	letter-spacing: $header_megamenu_CTALabel_LetterSpacing;
	text-transform: $header_megamenu_CTALabel_TextTransform;
	text-align:left;
}
.header--main--nav--megamenu .header--megamenu--cta--button--wrapper {
	padding:10px;
	flex:0 0 auto;
	white-space:nowrap;
	text-align:right;
}
.header--main--nav--megamenu .header--megamenu--cta--button--wrapper .header--megamenu--cta--button {
	font-size: 16px;
	padding: 19px 26px;
	margin-right: 10px;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent.active > .header--main--nav--megamenu {
	height:auto;
	overflow:visible;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent:focus-within > .header--main--nav--megamenu {
	height:auto;
	overflow:visible;
}
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent.active > .header--main--nav--nestedmenu,
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent.active > .header--main--nav--item--label:before,
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent.active > .header--main--nav--item--label:after,
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent:focus-within > .header--main--nav--nestedmenu,
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent:focus-within > .header--main--nav--nestedmenu li.hs-item-has-children:focus-within > ul,
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent:focus-within > .header--main--nav--item--label:before,
.header--main--nav > .header--main--nav--item.header--main--nav--item--parent:focus-within > .header--main--nav--item--label:after {
	display:block;
}
.header--main--nav > .header--main--nav--item:hover > .header--main--nav--item--label > .header--main--nav--item--label--inner:before,
.header--main--secondarynav > .header--main--secondarynav--item:hover > .header--main--secondarynav--item--label > .header--main--secondarynav--item--label--inner:before {
	opacity:1;
	filter:alpha(opacity=100);
	background: $header_main_HoverBorderColor;
}

/****************************
	HEADER MAIN CTA
*****************************/
.btn.header--main--cta {
	display:inline-block;
	float:none;
	margin-top:29px;
	margin-bottom:30px;
	margin-left:10px;
	padding: 10px 25px;
	line-height: 22px;
	position:relative;
	z-index:6;
	border-width:0px!important;
	background:  $header_main_CTAColor;
	font-size: 16px;
}
.btn.header--main--cta:hover {
	background:  $header_main_HoverCTAColor !important;
}

/*****************************
	HEADER MAIN MENUTRIGGER
*****************************/
.header--main--menutrigger {
	display:block;
	position:relative;
	z-index:2;
	float:right;
	margin-top:28px;
	margin-bottom:28px;
	margin-right:6px;
	text-decoration:none!important;
}
.header--main--menutrigger--icon {
	cursor: pointer;
	display: block;
	height: 24px;
	position: relative;
	transform: scale(.75);
	transition: 0.5s ease-in-out;
	width: 30px;
}
.header--main--menutrigger--icon span {
	background: $header_menuTrigger_Color;
	border-radius: 0;
	display: block;
	height: 3px;
	left: 0;
	opacity: 1;
	position: absolute;
	transform: rotate(0deg);
	transition: 0.25s ease-in-out;
	width: 100%;
}
.header--main--menutrigger--icon span:first-child {
	top: 0;
}
.header--main--menutrigger--icon span:nth-child(2),
.header--main--menutrigger--icon span:nth-child(3) {
	top: 9px;
}
.header--main--menutrigger--icon span:nth-child(4) {
	top: 18px;
}
.header--main--menutrigger:hover {
	cursor:pointer;
}

/*****************************
	HEADER MAIN STICKY
*****************************/
body.header--hidden header .header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--megamenu {
	height:0px!important;
	overflow:hidden!important;
}
body.header--hidden header .header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--nestedmenu,
body.header--hidden header .header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--item--label:before,
body.header--hidden header .header--main--nav > .header--main--nav--item.header--main--nav--item--parent > .header--main--nav--item--label:after {
	display:none!important;
}

/*****************************
	HEADER MAIN TRANSPARENT
*****************************/
@include media-query("lap-and-up") {
	body.header--transparent .header--divider {
		display:block;
		margin-left:-20px;
		margin-right:-20px;
		height:1px;
		margin-top:-1px;
		background: $header_TransparentDividerColor;
		opacity:.25;
		filter:alpha(opacity=25);
		transition: opacity .3s ease-in-out;
	}
	body.header--transparent.header--fixed .header--divider {
		opacity:0;
		filter:alpha(opacity=0);
	}
	body.header--transparent.header--notfixed header {
		background:transparent!important;
		-webkit-box-shadow: $header_TransparentBoxShadow;
		-moz-box-shadow: $header_TransparentBoxShadow;
		box-shadow: $header_TransparentBoxShadow;
	}
	body.header--transparent.header--notfixed header .header--main--logo .header--main--logo--default {
		opacity:0;
		filter:aplha(opacity=0);
	}
	body.header--transparent.header--notfixed header .header--main--logo .header--main--logo--white {
		opacity:1;
		filter:aplha(opacity=1);
	}
	body.header--transparent.header--notfixed header .header--main--cta.btn {
		background: $header_main_TransparentCTAColor;
		color: $header_main_TransparentCTAFontColor;
	}
	body.header--transparent.header--notfixed header .header--main--cta.btn:hover,
	body.header--transparent.header--notfixed header .header--main--cta.btn:focus {
		background: $header_main_TransparentHoverCTAColor;
		color: $header_main_TransparentHoverCTAFontColor;
	}
	body.header--transparent.header--notfixed header .header--top--nav .header--top--nav--item > .header--top--nav--item--label,
	body.header--transparent.header--notfixed header .header--main--nav .header--main--nav--item > .header--main--nav--item--label,
	body.header--transparent.header--notfixed header .header--main--secondarynav .header--main--secondarynav--item > .header--main--nav--item--label,
	body.header--transparent.header--notfixed header .header--main--secondarynav .header--main--secondarynav--item > .header--main--secondarynav--item--label,
	body.header--transparent.header--notfixed header .header--main--search--trigger--icon {
		color:color("white")!important;
	}
	body.header--transparent.header--notfixed header .header--main--nav > .header--main--nav--item > .header--main--nav--item--label > .header--main--nav--item--label--inner:before {
		background: color('white');
	}
	body.header--transparent.header--notfixed header .header--main--nav .header--main--nav--item > a:hover,
	body.header--transparent.header--notfixed header .header--main--secondarynav .header--main--secondarynav--item > a:hover {
		color:color("white")!important;
	}
	body.header--transparent.header--notfixed header .header--main--search--trigger:hover .header--main--search--trigger--icon {
		color:color("white")!important;
	}
	body.header--transparent.header--notfixed header .header--main--menutrigger--icon span {
		background:color("white")!important;
	}
}
@media (max-width:1320px) {
	body.header--transparent .header--divider {
		margin-left:1.75%;
		margin-right:1.75%;
		width:96.5%;
	}
}

/*****************************
	HEADER MAIN RESPONSIVE
*****************************/
@media (max-width:1720px) {
	header .header--body {
		padding-left: 0;
		padding-right: 0;
	}
	.header--main--nav > .header--main--nav--item > .header--main--nav--item--label,
	.header--main--secondarynav > .header--main--secondarynav--item > .header--main--secondarynav--item--label {
		padding-left:17px;
		padding-right:17px;
	}
}
@media (max-width:1620px) {
	.header--main--nav > .header--main--nav--item > .header--main--nav--item--label,
	.header--main--secondarynav > .header--main--secondarynav--item > .header--main--secondarynav--item--label {
		padding-left:17px;
		padding-right:17px;
		font-size:14px;
	}
	.header--main .header--main--cta {
		font-size: 14px;
		padding: 10px 15px;
	}
	.header--main .header--main--search--trigger--icon {
		font-size: 14px;
	}
}
@media (max-width:1290px) {
	.header--main--logo--default > img,
	.header--main--logo--white > img {
		height: 66px;
	}
	.header--top--nav--item--label * {
		font-size: 12px;
	}
	.header--main--nav > .header--main--nav--item > .header--main--nav--item--label,
	.header--main--secondarynav > .header--main--secondarynav--item > .header--main--secondarynav--item--label {
		padding-left:10px;
		padding-right:10px;
		font-size:12px;
	}
	.header--main .header--main--cta {
		font-size: 12px;
	}
	.header--main .header--main--search--trigger--icon {
		font-size: 14px;
	}
	.header--main .header--main--search {
		margin: 0;
	}
}
@include media-query("portable") {
	.header--main--logo {
		margin-top:0!important;
		margin-bottom:0!important;
	}
	.header--main--logo img {
		height:66px !important;
		width:auto !important;
	}
}

/*****************************
	MOBILE NAV
*****************************/
.mobilenav {
	display:block;
	position:absolute;
	top:100%;
	left:0;
	height:0px;
	width:100%;
	overflow:hidden;
	background: $mobile_BackgroundColor;
	z-index:101;
	transition: height .3s ease-in-out;
}
@include media-query('lap-and-up') {
	.mobilenav {
		display:none!important;
	}
}
@include media-query('portable') {
	body.mobilenav--active {
		overflow:hidden;
	}
}
.mobilenav--search--input {
	position: relative;
}
.mobilenav--search--input--input {
	background-color: color('lightgrey') !important;
	margin-bottom: 0 !important;
	border: 0;
}
.mobilenav--search--input .mobilenav--search--search {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 10px;
	color: color('primary');
}
.mobilenav--nav--wrapper {
	display: block;
	position: absolute;
	width: 100%;
	overflow: visible;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.mobilenav--nav {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:visible;
	transition: left .5s ease-in-out;
}
.mobilenav--panel {
	display:block;
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:100%;
	z-index:1;
	background: $mobile_BackgroundColor;
	overflow:auto;
	padding:0px 10px;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.mobilenav--panel--outer {
	display:block;
	margin-left:auto;
	margin-right:auto;
	width: #{ round( $grid--width / (( 100 - $grid--column-spacing ) / 100 ) ) }!important;
	max-width: $grid--max-width-percent !important;
}
.mobilenav--panel--inner {
	display:block;
	margin-left:auto;
	margin-right:auto;
	padding-bottom:20px;
	width: #{ (100 * ( 12 / 12 ) - $grid--column-spacing ) * 1% };
}
.mobilenav--panel--body {
	display:block;
	width:100%;
	padding-left:0px;
	padding-right:0px;
}
.mobilenav--panel--depth--0 {
	left:0;
}
.mobilenav--panel--depth--1 {
	left:100%;
}
.mobilenav--panel--depth--2 {
	left:200%;
}
.mobilenav--panel--depth--3 {
	left:300%;
}
.mobilenav--panel--depth--4 {
	left:400%;
}
.mobilenav--panel--depth--5 {
	left:500%;
}
.mobilenav--panel--depth--6 {
	left:600%;
}
.mobilenav--nav--back {
	display:block;
	position:static;
	width:100%;
	padding:9px 0px 10px;
}
.mobilenav--nav--back--label {
	display:block;
	position:relative;
	padding:17px 20px;
	width:100%;
	font-family: $mobile_backLabel_FontFamily;
	font-size: $mobile_backLabel_FontSize;
	font-weight: $mobile_backLabel_FontWeight;
	color: $mobile_backLabel_FontColor;
	line-height: $mobile_backLabel_LineHeight;
	text-transform: $mobile_backLabel_TextTransform;
	letter-spacing: $mobile_backLabel_LetterSpacing;
	text-align:center;
	transition: color .3s ease-in-out, background .3s ease-in-out;
}
.mobilenav--nav--back--label:before {
	content:"\f0d9";
	font-family: font("fontawesome");
	display:block;
	position:absolute;
	top:0px;
	left:0px;
	line-height:14px;
	margin-top:24px;
	font-size:1em;
	font-weight:600;
	color: $mobile_backLabel_FontColor;
}
.mobilenav--nav--item {
	display:block;
	position:static;
	width:100%;
	border-bottom: 1px solid  $mobile_BorderColor;
}
.mobilenav--nav--item:last-child {
	margin-bottom:0px;
}
.mobilenav--nav--back--label:hover {
	color: $mobile_backLabel_HoverFontColor;
}
.mobilenav--nav--item--label {
	display:block;
	position:relative;
	width:100%;
	font-family: $mobile_label_FontFamily;
	font-size: $mobile_label_FontSize;
	font-weight: $mobile_label_FontWeight;
	color: $mobile_label_FontColor;
	line-height: $mobile_label_LineHeight;
	padding:21px 0px;
	text-decoration: $mobile_label_TextDecoration!important;
	transition: color .3s ease-in-out, background .3s ease-in-out;
}
.mobilenav--nav--item--label:hover {
	color: $mobile_label_HoverFontColor;
}
.mobilenav--nav--item--label:hover,
.mobilenav--nav--back--label:hover {
	cursor:pointer;
}
.mobilenav--nav--item--parent > .mobilenav--nav--item--label {
	padding-right:20px;
}
.mobilenav--nav--item--parent > .mobilenav--nav--item--label:after {
	content:"\f0da";
	font-family: font("fontawesome");
	display:block;
	position:absolute;
	top:50%;
	right:0px;
	line-height:14px;
	margin-top:-7px;
	font-size:1em;
	font-weight:600;
	color: color('primary');
}
.mobilenav--panel--depth--0 .mobilenav--nav--item--label {
	font-family:  $mobile_depth0Label_FontFamily;
	font-size:  $mobile_depth0Label_FontSize;
	font-weight: $mobile_depth0Label_FontWeight;
	text-transform: $mobile_depth0Label_TextTransform;
	line-height: $mobile_depth0Label_LineHeight;
	letter-spacing: $mobile_depth0Label_LetterSpacing;
	color: $mobile_depth0Label_FontColor;
	font-size: 14px;
}
.mobilenav--panel--depth--0 .mobilenav--nav--item--label:hover {
	color: $mobile_depth0Label_HoverFontColor;
}

.mobilenav--panel--megagroup .mobilenav--nav--item--label .mobilenav--nav--item--label--container {
	display: block;
}
.mobilenav--panel--megagroup .mobilenav--nav--item--label--label {
	display: block;
	font-weight: $bold;
}
.mobilenav--panel--megagroup .mobilenav--nav--item--label--description {
	display: block;
	font-size: .9em;
}
.mobilenav--panel--megagroup .mobilenav--nav--item--label.flex {
	display: flex;
}
.mobilenav--panel--megagroup .mobilenav--nav--item--label.flex .mobilenav--nav--item--label--icon {
	flex: 0 0 45px;
	padding-right: 15px;
}
.mobilenav--panel--megagroup .mobilenav--nav--item--label.flex .mobilenav--nav--item--label--icon > img {
	width: 100%;
}
.mobilenav--panel--megagroup .mobilenav--nav--item--label.flex .mobilenav--nav--item--label--container {
	flex: 1;
}
.mobilenav--form--wrapper {
	display:block;
	width:100%;
	padding:15px 0px 10px;
}
.mobilenav--cta--spacer {
	display:block;
}
.mobilenav--cta--wrapper {
	display:block;
	padding:21px 0px 0px;
}
.mobilenav--cta {
	margin-top:5px;
}
.mobilenav--cta--label {
	display:block;
	font-family: font("heading");
	font-weight: $normal;
	color:color("grey");
	font-size:16px;
	line-height:1.36em;
	text-align:left;
	margin-bottom:18px;
}

/*****************************
	BODY CLASS OVERRIDES
*****************************/
body.header--initialized header {
	position:fixed;
	z-index:102;
	top:0;
	left:0;
}
body.header--initialized.admin-bar header {
	top: 32px;
}
body.header--static header {
	position:relative;
	-webkit-box-shadow:none!important;
	-moz-box-shadow:none!important;
	box-shadow:none!important;
}
body.header--hidden header {
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0)!important;
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0)!important;
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0)!important;
}
body.header--fixed .header--top,
body.header--fixed .header--hello {
	opacity: 0;
	max-height: 0;
	pointer-events: none;
}
body.mobilenav--active .header--main--menutrigger--icon span:first-child {
	left: 50%;
	top: 9px;
	width: 0;
}
body.mobilenav--active .header--main--menutrigger--icon span:nth-child(2) {
	transform: rotate(45deg);
}
body.mobilenav--active .header--main--menutrigger--icon span:nth-child(3) {
	transform: rotate(-45deg);
}
body.mobilenav--active .header--main--menutrigger--icon span:nth-child(4) {
	left: 50%;
	top: 18px;
	width: 0;
}
body.mobilenav--active .header--main--search {
	opacity: 0;
}
body.header--search--showing .header--main--nav {
	opacity:0;
	filter:alpha(opacity=0);
	pointer-events:none;
}

body.no--navigation header .header--top,
body.no--navigation header .header--main--menutrigger,
body.no--navigation header .header--main--nav,
body.no--navigation header .header--main--secondarynav,
body.no--navigation header .header--main--cta,
body.no--navigation header .header--main--search,
body.no--navigation .mobilenav {
	display:none!important;
}
