// main: ../production.scss

/* ********** INITIALIZE ********** */

* {
	@include box-sizing(border-box);
}

html, body {
	padding:0px;
	margin:0px;
	// opacity: 1 !important;
	-webkit-font-smoothing: antialiased !important;
	text-rendering: optimizeLegibility;
	overflow-x: hidden;
}

body {
	&.no-navigation {
		.header--top--nav, .header--top--nav--btn, .footer--main {
			display: none !important;
		}
	}
}

.grid__item {
	img, iframe {
		max-width:100%;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			width: 100%\9;
		}
	}
}

/* ********** GRID ********** */

// Checks $grid--column-spacing to ensure it is possible with a 12-column grid
@if ($grid--column-spacing * 12 > 100) {
	@error "Grid spacing '#{$grid--column-spacing}%' is too large. Max is "+(100/12)+"%.";
}
@if ($grid--column-spacing < 0) {
	@error "Grid spacing '#{$grid--column-spacing}%' is invalid. Grid spacing cannot be negative.";
}

/* Grid */
.grid {
	margin:0px auto!important;
	width: round($grid--width/((100-$grid--column-spacing)/100))!important;
	max-width:$grid--max-width-percent!important;
	text-align:center;
	font-size:0px;
	@include box-sizing(border-box);
	padding-top: $short;
	padding-bottom:$short;

	&.hard, &.hard--sides {
		width:100%!important;
		max-width:100%!important;
	}
}

@media screen and (max-width:768px) and (min-width:415px) {
  .grid {
    max-width: 80%!important;
  }
}

@include media-query('palm') {
	.grid {
		max-width: 90%!important;
	}
}

.grid.flexgrid,
.flexgrid {
  box-sizing: border-box;
  display: flex !important;
  flex-direction: row;
	flex-wrap: wrap;
  margin: 0;
  justify-content: space-between;
}
.flexgrid {
	> .grid__item {
		box-sizing: border-box;
		max-width: 100%;
		flex: none;
	}
	.flex__grid__item {
		flex: 1 1 0%;
	}
	&.no--wrap,
	.no--wrap {
		flex-wrap: nowrap;
	}
	&.align--left,
	.align--left,
	&.justify--left,
	.justify--left,
	&.justify--start,
	.justify--start {
		justify-content: left;
	}
	&.align--center,
	.align--center,
	&.justify--center,
	.justify--center {
		justify-content: center;
	}
	&.align--right,
	.align--right {
		align-self: flex-end;
		justify-content: flex-end;
		margin-left: auto;
	}
	&.valign--top,
	.valign--top {
		align-items: flex-start;
	}
	&.valign--middle,
	.valign--middle {
		align-items: center;
	}
	&.valign--bottom,
	.valign--bottom {
		align-items: flex-end;
	}
	&.justify--center,
	.justify--center {
		justify-content: center;
	}
	&.row--reverse,
	.row--reverse {
		flex-direction: row-reverse;
	}
	&.direction--column,
	.direction--column {
		flex-direction: column;
	}
	&.direction--column > .grid__item,
	.direction--column > .grid__item {
		flex-basis: auto;
	}
	&.column--reverse,
	.column--reverse {
		flex-direction: column-reverse;
	}
	&.space--between,
	.space--between {
		justify-content: space-between;
	}
	&.space--around,
	.space--around {
		justify-content: space-around;
	}
	&.equal--height {
		> .grid__item {
			align-self: stretch;
			-ms-grid-row-align: stretch;
			> * {
				height: 100%;
			}
		}
	}
	.grid__item {
		&.self--flex,
		.self--flex {
			display: flex;
			flex-shrink: 1;
			flex-grow: 1;
		}
		&.self--stretch,
		.self--stretch {
			height: 100%;
		}
		&.self--top,
		.self--top {
			align-self: flex-start;
		}
		&.self--middle,
		.self--middle {
			align-self: center;
			-ms-grid-row-align: center;
		}
		&.self--bottom,
		.self--bottom {
			align-self: flex-end;
		}
		&.self--center,
		.self--center {
			justify-self: center;
		}
		&.self--first,
		.self--first {
			order: -1;
		}
		&.self--last,
		.self--last {
			order: 1;
		}
	}
}

/* Grid Items */
.grid__item {
	display:inline-block;
	vertical-align:top;
	padding-top: $short;
	padding-bottom:$short;
	margin-left:$grid--column-spacing/2+0%;
	margin-right:$grid--column-spacing/2+0%;
}

/* Grid Column Widths */
$grid-shorthand-list: one-whole, one-half, one-third, one-fourth, one-fifth, one-sixth;
$grid-main-list: null;
@function gridAppend($item,$val) {
	@if ($grid-main-list == null) {
		@return (#{$val}: (unquote("#{$item}")));
	}
	@else {
		@if (map-get($grid-main-list, #{$val}) == null) {
			@return map-merge($grid-main-list, (#{$val}: (unquote("#{$item}"))));
		}
		@else {
			$list: map-get($grid-main-list, #{$val});
			@return map-merge($grid-main-list, (#{$val}: append($list, unquote("#{$item}"), comma)));
		}
	}
	@error "Unexpected error.";
}
@for $i from 1 through 12 {
	$val: (100*($i/12)-($grid--column-spacing));
	$item: MEDIA#{gridNumToString($i)+'-twelfths.grid__item'};
	$grid-main-list: gridAppend($item,$val);

	$val: (100*($i/12)-($grid--column-spacing*0));
	$item: MEDIA#{gridNumToString($i)+'-twelfths'+'.hard.grid__item'};
	$grid-main-list: gridAppend($item,$val);

	$val: (100*($i/12)-($grid--column-spacing*0));
	$item: MEDIA#{gridNumToString($i)+'-twelfths'+'.hard--sides.grid__item'};
	$grid-main-list: gridAppend($item,$val);
}
@for $i from 3 through 9 {
	$val: (100*( calc( $i / 12 ) ));
	$item: MEDIA#{gridNumToString($i)+'-half-twelfths.grid__item'};
	$grid-main-list: gridAppend($item,$val);
}
@for $i from 1 through 10 {
	$val: (100*($i/10)-($grid--column-spacing));
	$item: MEDIA#{gridNumToString($i)+'-tenths.grid__item'};
	$grid-main-list: gridAppend($item,$val);

	$val: (100*($i/10)-($grid--column-spacing*0));
	$item: MEDIA#{gridNumToString($i)+'-tenths'+'.hard.grid__item'};
	$grid-main-list: gridAppend($item,$val);

	$val: (100*($i/10)-($grid--column-spacing*0));
	$item: MEDIA#{gridNumToString($i)+'-tenths'+'.hard--sides.grid__item'};
	$grid-main-list: gridAppend($item,$val);
}
@for $i from 1 through 8 {
	$val: (100*($i/8)-($grid--column-spacing));
	$item: MEDIA#{gridNumToString($i)+'-eighths.grid__item'};
	$grid-main-list: gridAppend($item,$val);

	$val: (100*($i/8)-($grid--column-spacing*0));
	$item: MEDIA#{gridNumToString($i)+'-eighths'+'.hard.grid__item'};
	$grid-main-list: gridAppend($item,$val);

	$val: (100*($i/8)-($grid--column-spacing*0));
	$item: MEDIA#{gridNumToString($i)+'-eighths'+'.hard--sides.grid__item'};
	$grid-main-list: gridAppend($item,$val);
}
@each $gridItem in $grid-shorthand-list {
	$val: (100*gridStringToNum(#{nth($gridItem,1)})-($grid--column-spacing));
	$item: MEDIA#{nth($gridItem,1)}#{'.grid__item'};
	$grid-main-list: gridAppend($item,$val);

	$val: (100*gridStringToNum(#{nth($gridItem,1)})-($grid--column-spacing*0));
	$item: MEDIA#{nth($gridItem,1)+'.hard.grid__item'};
	$grid-main-list: gridAppend($item,$val);

	$val: (100*gridStringToNum(#{nth($gridItem,1)})-($grid--column-spacing*0));
	$item: MEDIA#{nth($gridItem,1)+'.hard--sides.grid__item'};
	$grid-main-list: gridAppend($item,$val);
}
@include media-loop() {
	@each $key, $value in $grid-main-list {
		$list:null;
		@each $item in map-get($grid-main-list, $key) {
			@if ($list == null) {
				$list: #{str-replace($item, 'MEDIA', #{$media})};
			}
			@else {
				$list: #{$list}, #{'.'}#{str-replace($item, 'MEDIA', #{$media})};
			}
		}
		@if ($list != null) {
			.#{$list} {
				width: #{$key+'%'}#{$mediaImportant};
				flex-basis: #{$key+'%'}#{$mediaImportant};
			}
		}
	}
	.flexgrid {
		&.#{$media}valign--top,
		.#{$media}valign--top {
			align-items: flex-start;
		}
		&.#{$media}valign--middle,
		.#{$media}valign--middle {
			align-items: center;
		}
		&.#{$media}valign--bottom,
		.#{$media}valign--bottom {
			align-items: flex-end;
		}
		&.#{$media}justify--center,
		.#{$media}justify--center {
			justify-content: center;
		}
		&.#{$media}row--reverse,
		.#{$media}row--reverse {
			flex-direction: row-reverse;
		}
		&.#{$media}direction--column,
		.#{$media}direction--column {
			flex-direction: column;
		}
		&.#{$media}direction--column > .grid__item,
		.#{$media}direction--column > .grid__item {
			flex-basis: auto;
		}
		&.#{$media}column--reverse,
		.#{$media}column--reverse {
			flex-direction: column-reverse;
		}
		.grid__item {
			&.#{$media}self--stretch,
			.#{$media}self--stretch {
				height: 100%;
			}
			&.#{$media}self--top,
			.#{$media}self--top {
				align-self: flex-start;
			}
			&.#{$media}self--middle,
			.#{$media}self--middle {
				align-self: center;
				-ms-grid-row-align: center;
			}
			&.#{$media}self--bottom,
			.#{$media}self--bottom {
				align-self: flex-end;
			}
			&.#{$media}self--center,
			.#{$media}self--center {
				justify-self: center;
			}
			&.#{$media}self--first,
			.#{$media}self--first {
				order: -1;
			}
			&.#{$media}self--last,
			.#{$media}self--last {
				order: 1;
			}
		}
	}
}

/******************** BASE ********************/
* {
	@include box-sizing(border-box);
}
html, body {
	padding:0px;
	margin:0px;
	opacity:1!important;
}
body {
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
}
a img {
	border:0px;
	outline:none;
}
.background {
	display:block;
	position:absolute;
	top:0;
	left:0;
	bottom:0;
	right:0;
	z-index:1;
}
.background--layer {
	display:block;
	position:absolute;
	top:0;
	left:0;
	bottom:0;
	right:0;
}
.background--video {
	background-size:cover;
	opacity:0;
	filter:alpha(opacity=0);
	@include transition(opacity .2s ease-in-out);
}
.background--video.background--video--active {
	opacity:1;
	filter:alpha(opacity=100);
}
.background--video[poster] {
	object-fit:cover;
}
img.placeholder,
img.spacer {
	display:block;
	width:100%;
	height:auto!important;
	opacity:0;
	filter:alpha(opacity=0);
	visibility:hidden;
}

/* Grid Vertical Padding */
@include media-loop() {
	// Top & Bottom
	.#{$media}short {
		padding-top: unquote("#{$short}#{$mediaImportant}");
		padding-bottom: unquote("#{$short}#{$mediaImportant}");
	}
	.#{$media}tall {
		padding-top: unquote("#{$tall}#{$mediaImportant}");
		padding-bottom: unquote("#{$tall}#{$mediaImportant}");
	}
	.#{$media}grande {
		padding-top: unquote("#{$grande}#{$mediaImportant}");
		padding-bottom: unquote("#{$grande}#{$mediaImportant}");
	}
	.#{$media}venti {
		padding-top: unquote("#{$venti}#{$mediaImportant}");
		padding-bottom: unquote("#{$venti}#{$mediaImportant}");
	}
	.#{$media}trenta {
		padding-top: unquote("#{$trenta}#{$mediaImportant}");
		padding-bottom: unquote("#{$trenta}#{$mediaImportant}");
	}
	.#{$media}cuarenta {
		padding-top: unquote("#{$cuarenta}#{$mediaImportant}");
		padding-bottom: unquote("#{$cuarenta}#{$mediaImportant}");
	}

	// Top
	.#{$media}short--top {
		padding-top: unquote("#{$short}#{$mediaImportant}");
	}
	.#{$media}tall--top {
		padding-top: unquote("#{$tall}#{$mediaImportant}");
	}
	.#{$media}grande--top {
		padding-top: unquote("#{$grande}#{$mediaImportant}");
	}
	.#{$media}venti--top {
		padding-top: unquote("#{$venti}#{$mediaImportant}");
	}
	.#{$media}trenta--top {
		padding-top: unquote("#{$trenta}#{$mediaImportant}");
	}
	.#{$media}cuarenta--top {
		padding-top: unquote("#{$cuarenta}#{$mediaImportant}");
	}

	// Bottom
	.#{$media}short--bottom {
		padding-bottom: unquote("#{$short}#{$mediaImportant}");
	}
	.#{$media}tall--bottom {
		padding-bottom: unquote("#{$tall}#{$mediaImportant}");
	}
	.#{$media}grande--bottom {
		padding-bottom: unquote("#{$grande}#{$mediaImportant}");
	}
	.#{$media}venti--bottom {
		padding-bottom: unquote("#{$venti}#{$mediaImportant}");
	}
	.#{$media}trenta--bottom {
		padding-bottom: unquote("#{$trenta}#{$mediaImportant}");
	}
	.#{$media}cuarenta--bottom {
		padding-bottom: unquote("#{$cuarenta}#{$mediaImportant}");
	}
}

	/* Hard Grid */
	@include media-loop() {
		.#{$media}hard, .#{$media}hard--top, .#{$media}hard--ends {
			padding-top:0px#{$important};
			margin-top:0px#{$important};
		}
		.#{$media}hard, .#{$media}hard--right, .#{$media}hard--sides {
			padding-right:0px#{$important};
			margin-right:0px#{$important};
		}
		.#{$media}hard, .#{$media}hard--bottom, .#{$media}hard--ends {
			padding-bottom:0px#{$important};
			margin-bottom:0px#{$important};
		}
		.#{$media}hard, .#{$media}hard--left, .#{$media}hard--sides {
			padding-left:0px#{$important};
			margin-left:0px#{$important};
		}
		.#{$media}first--hard--top > p:first-child {
			padding-top:0px#{$important};
			margin-top:0px#{$important};
		}
		.#{$media}last--hard--bottom > p:last-child {
			padding-bottom:0px#{$important};
			margin-bottom:0px#{$important};
		}
	}

	@include media-loop() {
		.#{$media}negative--basic--top {
			margin-top: unquote("#{-1*$basic}#{$mediaImportant}") !important;
		}
		.#{$media}negative--short--top {
			margin-top: unquote("#{-1*$short}#{$mediaImportant}") !important;
		}
		.#{$media}negative--tall--top {
			margin-top: unquote("#{-1*$tall}#{$mediaImportant}") !important;
		}
		.#{$media}negative--grande--top {
			margin-top: unquote("#{-1*$grande}#{$mediaImportant}") !important;
		}
		.#{$media}negative--venti--top {
			margin-top: unquote("#{-1*$venti}#{$mediaImportant}");
		}
		.#{$media}negative--trenta--top {
			margin-top: unquote("#{-1*$trenta}#{$mediaImportant}");
		}
		.#{$media}negative--cuarenta--top {
			margin-top: unquote("#{-1*$cuarenta}#{$mediaImportant}");
		}

		.#{$media}negative--basic--bottom {
			margin-bottom: unquote("#{-1*$basic}#{$mediaImportant}");
		}
		.#{$media}negative--short--bottom {
			margin-bottom: unquote("#{-1*$short}#{$mediaImportant}");
		}
		.#{$media}negative--tall--bottom {
			margin-bottom: unquote("#{-1*$tall}#{$mediaImportant}");
		}
		.#{$media}negative--grande--bottom {
			margin-bottom: unquote("#{-1*$grande}#{$mediaImportant}");
		}
		.#{$media}negative--venti--bottom {
			margin-bottom: unquote("#{-1*$venti}#{$mediaImportant}");
		}
		.#{$media}negative--trenta--bottom {
			margin-bottom: unquote("#{-1*$trenta}#{$mediaImportant}");
		}
		.#{$media}negative--cuarenta--bottom {
			margin-bottom: unquote("#{-1*$cuarenta}#{$mediaImportant}");
		}
	}

	/* Floats */
	@include media-loop() {
		.#{$media}left {
			float:left;
		}
		.#{$media}right {
			float:right;
		}
	}

	/* ********** LAYOUT ********** */

	/* Clear */

	.clear {
		display:inline;
	}
	.clear:after {
		display:table;
		clear:both;
		height:0px;
		width:0px;
	}
	$clear--loop:'';
	$i : 0;
	@each $mediaItem in $media--list {
		@if ($i == 0) {
			$clear--loop: $clear--loop + '.clear';
		}
		@else {
			$clear--loop: $clear--loop + ', .#{nth($mediaItem,1)}-clear';
		}

		$i : $i + 1;
	}
	#{$clear--loop} {
		display:inline;
		&:after {
			display:table;
			clear:both;
			height:0px;
			width:0px;
		}
	}
	@include media-loop() {
		.#{$media}clear:after {
			content:"";
		}
	}

	/* Padding & Margin */

	@include media-loop() {
		// Padding
		.#{$media}padding--basic {
			padding-left:$basic!important;
			padding-right:$basic!important;
		}
		.#{$media}padding--basic--right {
			padding-right:$basic!important;
		}
		.#{$media}padding--basic--left {
			padding-left:$basic!important;
		}
		.#{$media}padding--short {
			padding-left:$short!important;
			padding-right:$short!important;
		}
		.#{$media}padding--short--right {
			padding-right:$short!important;
		}
		.#{$media}padding--short--left {
			padding-left:$short!important;
		}
		.#{$media}padding--tall {
			padding-left:$tall!important;
			padding-right:$tall!important;
		}
		.#{$media}padding--tall--right {
			padding-right:$tall!important;
		}
		.#{$media}padding--tall--left {
			padding-left:$tall!important;
		}
		.#{$media}padding--grande {
			padding-left:$grande!important;
			padding-right:$grande!important;
		}
		.#{$media}padding--grande--right {
			padding-right:$grande!important;
		}
		.#{$media}padding--grande--left {
			padding-left:$grande!important;
		}
		.#{$media}padding--venti {
			padding-left:$venti!important;
			padding-right:$venti!important;
		}
		.#{$media}padding--venti--right {
			padding-right:$venti!important;
		}
		.#{$media}padding--venti--left {
			padding-left:$venti!important;
		}
		.#{$media}padding--trenta {
			padding-left:$trenta!important;
			padding-right:$trenta!important;
		}
		.#{$media}padding--trenta--right {
			padding-right:$trenta!important;
		}
		.#{$media}padding--trenta--left {
			padding-left:$trenta!important;
		}

		// Margin
		.#{$media}margin--basic, .#{$media}margin--basic--top {
			margin-top:$basic!important;
		}
		.#{$media}margin--basic, .#{$media}margin--basic--bottom {
			margin-bottom:$basic!important;
		}
		.#{$media}margin--short, .#{$media}margin--short--top {
			margin-top:$short!important;
		}
		.#{$media}margin--short, .#{$media}margin--short--bottom {
			margin-bottom:$short!important;
		}
		.#{$media}margin--tall, .#{$media}margin--tall--top {
			margin-top:$tall!important;
		}
		.#{$media}margin--tall, .#{$media}margin--tall--bottom {
			margin-bottom:$tall!important;
		}
		.#{$media}margin--grande, .#{$media}margin--grande--top {
			margin-top:$grande!important;
		}
		.#{$media}margin--grande, .#{$media}margin--grande--bottom {
			margin-bottom:$grande!important;
		}
		.#{$media}margin--venti, .#{$media}margin--venti--top {
			margin-top:$venti!important;
		}
		.#{$media}margin--venti, .#{$media}margin--venti--bottom {
			margin-bottom:$venti!important;
		}
		.#{$media}margin--trenta, .#{$media}margin--trenta--top {
			margin-top:$trenta!important;
		}
		.#{$media}margin--trenta, .#{$media}margin--trenta--bottom {
			margin-bottom:$trenta!important;
		}
	}

	/* Modifiers */
	@include media-loop() {
		// Display
		.#{$media}block {display:block!important;}
		.#{$media}table {display:table!important;}
		.#{$media}inline {display:inline!important;}
		.#{$media}inline-block {display:inline-block!important;}
		.#{$media}absolute {position:absolute!important;}
		.#{$media}relative {position:relative!important;}

		// Position
		.#{$media}left--0 {left:0;}
		.#{$media}right--0 {right:0;}
		.#{$media}top--0 {top:0;}
		.#{$media}bottom--0 {bottom:0;}
		.#{$media}left--auto {left:auto;}
		.#{$media}right--auto {right:auto;}
		.#{$media}top--auto {top:auto;}
		.#{$media}bottom--auto {bottom:auto;}

		// Horizontal Align
		.#{$media}text--center, .#{$media}all--text--center, .#{$media}all--text--center * {text-align:center;}
		.#{$media}text--left, .#{$media}all--text--left, .#{$media}all--text--left * {text-align:left;}
		.#{$media}text--right, .#{$media}all--text--right, .#{$media}all--text--right * {text-align:right;}

		// Vertical Align
		.#{$media}valign--top, .#{$media}all--valign--top, .#{$media}all--valign--top * {vertical-align:top;}
		.#{$media}valign--bottom, .#{$media}all--valign--bottom, .#{$media}all--valign--bottom * {vertical-align:bottom;}
		.#{$media}valign--middle, .#{$media}all--valign--middle, .#{$media}all--valign--middle * {vertical-align:middle;}

		// Floats
		.#{$media}float--center {display:block;margin:0px auto 25px;float:none;text-align:center;}
		.#{$media}float--left {margin-right:25px;margin-bottom:25px;float:left;max-width:25%!important;}
		.#{$media}float--right {margin-left:25px;margin-bottom:25px;float:right;max-width:25%!important;}

		// Overflows
		.#{$media}overflow--auto {overflow:auto!important;}
		.#{$media}overflow--hidden {overflow:hidden!important;}
		.#{$media}overflow-x--hidden {overflow-x:hidden!important;}
		.#{$media}overflow-y--hidden {overflow-y:hidden!important;}
		.#{$media}overflow--visible {overflow:visible!important;}
		.#{$media}overflow-x--visible {overflow-x:visible!important;}
    .#{$media}overflow-y--visible {overflow-y:visible!important;}
	}

	/* Visually Hidden */
	@include media-loop() {
		.#{$media}visuallyhidden {display:none!important;}
		@include media-query(touch) {
			.#{$media}touch-visuallyhidden {display:none!important;}
		}
	}
	html.no-opacity .lap-visuallyhidden,
	html.no-opacity .lap-and-up-visuallyhidden,
	html.no-opacity .desk-visuallyhidden {
		display:none;
	}

	/* Z-Index */

	@for $i from 0 through 20 {
		.z-index--#{($i)} {
			z-index: #{($i)}!important;
		}
	}
	.z-index--99 {
		z-index: 99!important;
	}

	/* Opacity */

	@for $i from 0 through 20 {
		.opacity--#{($i*5)}, .hover--opacity--#{($i*5)}:hover  {
			opacity: #{($i*5/100)}!important;
			filter: #{"alpha(opacity="}#{($i*5)}#{")"};
		}
	}

	/* Cursor */

	.hover--cursor:hover, .hover--pointer:hover {
		cursor:pointer;
	}
	.hover--default:hover {
		cursor: default;
	}

	/* Heights & Widths */

	@for $i from 0 through 20 {
		.width--#{($i * 5)} {
			width: #{($i * 5)}px;
		}
		.height--#{($i * 5)} {
			height: #{($i * 5)}px;
			line-height: #{($i * 5)}px;
		}
	}
	@include media-loop() {
		.#{$media}max-width--auto {max-width:none!important;}
		.#{$media}width--max {width:100%!important;}
		.#{$media}height--max {height:100%!important;}
		.#{$media}width--auto {width:auto!important;}
		.#{$media}height--auto {height:auto!important;}
	}

	/* ULs, OLs */

	.no--bullets, .no--bullets ul, .no--bullets li {list-style:none!important;}
	.no--underline, .no--underline * {text-decoration:none!important;}
	.underline, .all--underline, .all--underline * {text-decoration:underline!important;}

	/* Transition */
	.no--transition, .no--transition * {
		-webkit-transition: none !important;
		-moz-transition: none !important;
		-o-transition: none !important;
		-ms-transition: none !important;
		transition: none !important;
	}

	/* Equalize */

	span[class*="equalize"] {
		display:block;
	}
	.equalize--center {
		opacity:0;
		@include transition(opacity 1.25s ease-out);
	}

	/* MixItUp */

	.mix {
		display:none;
	}

	/* Modal */

	#production--modal {
		display:inline-block;
		position:fixed!important;
		overflow:auto;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:9999997!important;
		background-color:rgba(20,20,20,.85);
		text-align:center;
		-webkit-backface-visibility: hidden;
		#production--modal--center {
			display:inline-block;
			height:100%;
			vertical-align: middle;
		}
		#production--modal--grid {
			display:inline-block;
			vertical-align:middle;
			position:relative!important;
			#production--modal--close--wrapper {
				display:inline-block;
				position:absolute!important;
				z-index:9999998!important;
				top:30px;
				left:30px;
				height:46px;
				width:46px;
				border-radius:50%;
				text-align:center;
				background:#ababab!important;
				border-bottom:3px solid #797979;
				border-right:2px solid #797979;
				box-shadow: 0px 0px 40px 10px rgba(255,255,255,.1);
				@include transition(all .3s ease-out);
				&:hover {
					border-bottom-width:2px;
					border-right-width:1px;
					margin-top:1px;
					margin-left:1px;
					cursor:pointer;
					box-shadow: 0px 0px 40px 10px rgba(255,255,255,.2);
				}
				#production--modal--close {
					position:relative!important;
					z-index:99999999!important;
					font-size:28px;
					color:#ffffff;
					text-shadow: 0px 0px 2px rgba(50,50,50,.3);
					line-height:42px;
				}
			}
		}
	}

	/* Line Clamp */

	$clamp--loop:'';
	@for $i from 1 through 5 {
		@if ($i != 1) {
			$clamp--loop: $clamp--loop + ', '
		}
		$clamp--loop: $clamp--loop + '.clamp--'+$i
	}
	@supports (-webkit-line-clamp: 1) {
		#{$clamp--loop} {
			&:after {
				display : none !important;
			}
		}
	}
	@media screen and (-webkit-min-device-pixel-ratio:0) {
		#{$clamp--loop} {
			&:after {
				display : none !important;
			}
		}
	}
	#{$clamp--loop} {
		display: block;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		position: relative;
		line-height: 1.5;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 !important;
		position:relative;
		&:after {
			content: '...';
			text-align: right;
			bottom: 0;
			right: 0;
			width: 25%;
			display: block;
			position: absolute;
		}
	}
	@for $i from 1 through 5 {
		.clamp--#{$i} {
			-webkit-line-clamp: #{$i};
			height: calc(1em * 1.5 * #{$i});
			max-height: calc(1em * 1.5 * #{$i});
			&:after {
				height: calc(1em * 1.5);
			}
		}
	}
