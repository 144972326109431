$mainFooter_BackgroundColor     : color('darkblue');
$mainFooter_FontSize            : 16px;
$mainFooter_TextColor           : color('white');
$mainFooter_LinkColor           : color('white');
$mainFooter_HoverLinkColor      : color('white');
$mainFooter_TextDecoration      : none;
$mainFooter_HoverTextDecoration : underline;

$groupHeader_FontFamily         : font('heading');
$groupHeader_FontSize           : 15px;
$groupHeader_LineHeight         : #{ 26px / $groupHeader_FontSize }em;
$groupHeader_FontStyle          : undefined;
$groupHeader_FontWeight         : $bold;
$groupHeader_TextTransform      : uppercase;
$groupHeader_TextDecoration     : undefined;
$groupHeader_LetterSpacing      : undefined;
$groupHeader_WordSpacing        : undefined;
$groupHeader_Color              : color('white');
$groupHeader_BorderColor        : transparent;

$groupHeader_iconColor          : d2d2d2;

$footerMenu_FontFamily          : font('heading');
$footerMenu_FontSize            : 15px;
$footerMenu_LineHeight          : #{ 18px / $footerMenu_FontSize}em;
$footerMenu_FontStyle           : undefined;
$footerMenu_FontWeight          : $normal;
$footerMenu_TextTransform       : undefined;
$footerMenu_TextDecoration      : undefined;
$footerMenu_LetterSpacing       : undefined;
$footerMenu_WordSpacing         : undefined;
$footerMenu_Color               : color('white');
$footerMenu_HoverColor          : color('white');
$footerMenu_BorderColor         : color('darkblue');
$footerMenu_HoverBorderColor    : color('white');

$copyFooter_BackgroundColor     : color('blue');
$copyFooter_BorderColor         : color('primary');
$copyFooter_TextColor           : color('white');
$copyFooter_LinkColor           : color('white');
$copyFooter_HoverLinkColor      : color('white');
$copyFooter_TextDecoration      : none;
$copyFooter_HoverTextDecoration : none;



/* FOOTER GENERAL */
footer {
	display:block;
	position:relative;
	width:100%;
	background-color: $mainFooter_BackgroundColor;
}

/* FOOTER MAIN */
footer .footer--main li,
footer .footer--main p {
	color:$mainFooter_TextColor;
	font-size:$mainFooter_FontSize;
}
footer .footer--main a {
	color:$mainFooter_LinkColor;
	text-decoration:$mainFooter_TextDecoration;
}
footer .footer--main a:hover {
	color:$mainFooter_HoverLinkColor;
	text-decoration:$mainFooter_HoverTextDecoration;
}

.footer--top {
	p, li {
		color: $footerMenu_Color;
		font-size: 14px;
	}
	p > a {
		display: inline-block;
		font-weight: $semibold;
	}
	a {
		display: table !important;
		font-family: $footerMenu_FontFamily;
		font-size: $footerMenu_FontSize;
		line-height: $footerMenu_LineHeight;
		font-style: $footerMenu_FontStyle;
		font-weight: $semibold;
		text-transform: $footerMenu_TextTransform;
		text-decoration: $footerMenu_TextDecoration;
		letter-spacing: $footerMenu_LetterSpacing;
		word-spacing: $footerMenu_WordSpacing;
		color:$footerMenu_Color;
		padding-bottom:3px;
		margin-bottom:5px;
		border-bottom:1px solid $footerMenu_BorderColor;
		@include transition('all .3s ease-in-out');
		i {
			margin-right: 10px;
			font-size: 13px;
		}
	}
	a:hover {
		color:$footerMenu_HoverColor;
		border-bottom:1px solid $footerMenu_HoverBorderColor;
	}
	.footer--top--container {
		border:0;
		@include media-query('lap-and-up') {
			border-top: 1px solid color('white');
			border-bottom: 1px solid color('white');
		}
		.footer--top--column {
			border-left: 0;
			margin-left: #{ ($grid--column-spacing / 2) * 1% };
			margin-right: #{ ($grid--column-spacing / 2) * 1% };
			border-bottom: 1px solid color('white');
			@include media-query('lap-and-up') {
				padding: 0 0 0 30px;
				margin: 0;
				border-left: 1px solid color('white');
				border-bottom: 0;
				&:first-child {
					border-left: 0;
				}
			}
		}
	}
}

/* COLUMN CONTROLS */
.footer--main--column {
	display:inline-block;
	vertical-align:top;
	width:100%;
	margin-left: #{ ($grid--column-spacing / 2) * 1% };
	margin-right: #{ ($grid--column-spacing / 2) * 1% };
	width: #{ (100 - $grid--column-spacing) * 1% };
}
.footer--main--column.footer--main--column--2col .footer--main--menu--content > ul {
	columns:2;
	column-gap: #{ $grid--column-spacing * 1% };
}
.footer--main--column.footer--main--column--3col .footer--main--menu--content > ul {
	columns:3;
	column-gap: #{ $grid--column-spacing * 1% };
}

@include media-query('portable') {
	.footer--main--column .footer--main--menu--content > ul {
		columns:1!important;
	}
	.footer--main--column {
		width: #{ (100 - $grid--column-spacing) * 1% }!important;
	}
}

/* COLUMN HEADER */
.footer--group--header {
	font-family: $groupHeader_FontFamily;
	font-size: $groupHeader_FontSize;
	line-height: $groupHeader_LineHeight;
	font-style: $groupHeader_FontStyle;
	font-weight: $groupHeader_FontWeight;
	text-transform: $groupHeader_TextTransform;
	text-decoration: $groupHeader_TextDecoration;
	letter-spacing: $groupHeader_LetterSpacing;
	word-spacing: $groupHeader_WordSpacing;
	color:$groupHeader_Color;
	display:block;
	padding-bottom:0;
	margin-bottom:10px;
	border-bottom:1px solid $groupHeader_BorderColor;
	@include media-query('portable') {
		border-bottom:1px solid color('mediumgrey');
	}
}

/* COLUMN LOGO */
.footer--main--logo {
	max-width:200px!important;
}

/* COLUMN MENU/LINKS */
.footer--main .footer--richtext--icon--holder {
	width: 30px;
	position:relative;
	top:8px;
}
.footer--main .footer--links--icon--holder {
	width: 30px;
	position:relative;
	top:6px;
}
.footer--main .footer--main--menu--content ul {
	display:block;
	text-align:left;
	width:100%;
}
.footer--main .footer--main--menu--content ul li {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	float: none;
}
.footer--main .footer--main--menu--content ul li a {
	display: table!important;
	font-family: $footerMenu_FontFamily;
	font-size: $footerMenu_FontSize;
	line-height: $footerMenu_LineHeight;
	font-style: $footerMenu_FontStyle;
	font-weight: $footerMenu_FontWeight;
	text-transform: $footerMenu_TextTransform;
	text-decoration: $footerMenu_TextDecoration;
	letter-spacing: $footerMenu_LetterSpacing;
	word-spacing: $footerMenu_WordSpacing;
	color:$footerMenu_Color;
	padding-bottom:5px;
	margin-bottom:10px;
	border-bottom:1px solid $footerMenu_BorderColor;
	@include transition('all .3s ease-in-out');
}
.footer--main .footer--main--menu--content ul li a:hover {
	color:$footerMenu_HoverColor;
	border-bottom:1px solid $footerMenu_HoverBorderColor;
}

@include media-query('lap-and-up') {
	.footer--main--column .footer--main--menu--content {
		display:block!important;
		height:auto!important;
	}
}
@include media-query('portable') {
	.footer--main .footer--main--menu--content > ul {
		margin-top:20px!important;
	}
	.footer--main .footer--main--menu--content > ul > li {
		display:block;
	}
	.footer--main--column .footer--main--menu--content {
		display:none;
		width:100%;
	}
	.footer--main--menu--wrapper .footer--group--header {
		position:relative;
		margin-bottom:0px!important;
		padding-top:20px!important;
		padding-bottom:20px!important;
	}
	.footer--main--menu--wrapper .footer--group--header:after {
		content:"";
		background-image:url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23' + $groupHeader_iconColor + '" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>');
		background-size:12.25px;
		position:absolute;
		top:0px;
		bottom:0px;
		right:0px;
		width:42px;
		background-position:center;
		background-repeat:no-repeat;
	}
	.footer--main--menu--wrapper.footer--main--menu--open .footer--group--header:after {
		background-image:url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" class="svg-inline--fa fa-chevron-up fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23' + $groupHeader_iconColor + '" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>');
	}
	.footer--main--menu--wrapper .footer--group--header:hover {
		cursor:pointer;
		padding-right:42px;
	}
}

/* FOOTER COPYRIGHT */
footer .footer--copyright {
	display:block;
	background: $copyFooter_BackgroundColor;
}
footer .footer--copyright li,
footer .footer--copyright p {
	color:$mainFooter_TextColor;
}
footer .footer--copyright a {
	color:$mainFooter_LinkColor;
	text-decoration:$mainFooter_TextDecoration;
}
footer .footer--copyright a:hover {
	color:$mainFooter_HoverLinkColor;
	text-decoration:$mainFooter_HoverTextDecoration;
}
@include media-query('lap-and-up') {
	footer .footer--copyright .grid {
		border-top:1px solid $copyFooter_BorderColor;
	}
}

/* NO NAVIGATION */
body.no--navigation footer .footer--main {
	display:none!important;
}
