// main: ../production.scss

/* ********** BUTTONS ********** */

@include button() {
    display:inline-block;

    color: $btn--color;
    background-image:none;
    text-align: center;
    font-family: $btn--font-family;
    font-size: $btn--font-size;
    line-height: $btn--line-height;
    font-style: $btn--font-style;
    font-weight: $btn--font-weight;
    text-transform: $btn--text-transform;
    text-decoration: $btn--text-decoration;
    letter-spacing: $btn--letter-spacing;
    word-spacing: $btn--word-spacing;
    padding: $btn--vertical-padding $btn--horizontal-padding;
    border-radius: $btn--border-radius;
    min-width: $btn--min-width;
    width:auto;
    max-width:100% !important;

    border-width:$btn--border-width!important;
    border-style:solid!important;

    box-shadow:none!important;

    background-image:none!important;

    outline:none!important;

    -moz-box-sizing:border-box!important;
    -webkit-box-sizing:border-box!important;
    box-sizing:border-box!important;

    &:hover {
        cursor:pointer;
        color: $btn--color;
        background-color: color('secondary');
	}
	@if ( $btn--box-shadow != null ) {
		@include box-shadow( $btn--box-shadow );
	}
}
a.cta_button.btn {
    -moz-box-sizing:border-box!important;
    -webkit-box-sizing:border-box!important;
    box-sizing:border-box!important;
}

@include button('pill') {
    border-radius:9999px!important;
}


@include media-loop() {
    @include button("wide") {
        display:block;
        width:100%;
        min-width:100%;
        max-width:100%;
    }
}

@include media-loop() {
    @include button("narrow") {
        display:inline-block;
        width:auto;
        min-width:0px;
    }
}

@include button('small') {
    @if ($btn--font-size != null) {font-size:round($btn--font-size*.75);}
    @if ($btn--vertical-padding != null) {padding-top:round($btn--vertical-padding*.75);padding-bottom:round($btn--vertical-padding*.75);}
    @if ($btn--horizontal-padding != null) {padding-left:round($btn--horizontal-padding*.75);padding-right:round($btn--horizontal-padding*.75);}
}

@include button('large') {
    @if ($btn--font-size != null) {font-size:round($btn--font-size*1.25);}
    @if ($btn--vertical-padding != null) {padding-top:round($btn--vertical-padding*1.25);padding-bottom:round($btn--vertical-padding*1.25);}
    @if ($btn--horizontal-padding != null) {padding-left:round($btn--horizontal-padding*1.25);padding-right:round($btn--horizontal-padding*1.25);}
}

@include color-loop {
    @include button(#{$name}) {
        background-color: #{$color};
        border-color: #{$color};
        &:hover {
            @if ($btn--shading < 0) {
                border-color: darken($color, abs($btn--shading))!important;
                background-color: darken($color, abs($btn--shading))!important;
            } @else if ($btn--shading >= 0) {
                border-color: lighten($color, abs($btn--shading))!important;
                background-color: lighten($color, abs($btn--shading))!important;
            }
        }
    }
}
@include color-loop {
    @include button(#{"transparent--"+$name}) {
        background-color: transparent!important;
        color:#{$color};
        border-color: #{$color}!important;
        &:hover {
            background-color: $color!important;
            @if ($name == 'white') {
                color: $btn--color;
            } @else {
                color: white;
            }
        }
    }
}

.btn--white, .all--button--white input[type="submit"].wpcf7-submit {
	color: color('primary') !important;
	&:hover {
		background: color('secondary') !important;
		background-color: color('secondary') !important;
		border-color: color('secondary') !important;
		color: color('white') !important;
	}
}

.btn--transparent--white:hover {
    color: color('primary') !important;
}

a.simple, span.simple {
	display: inline-block;
	color: color('primary');
	font-weight: $normal !important;
	&:after {
		content: '\f105';
		font-family: Fontawesome, 'Font Awesome 5 Free';
		font-weight: 900;
		display: inline-block;
		transform: translateX(0);
		transition: all .3s ease-in-out;
		font-size: 14px;
	}
	&:hover {
		color: color('secondary');
		text-decoration: none;
		&:after {
			transform: translateX(5px);
		}
	}
}

.btn {
	@include transition(all .25s ease-in-out);
}

@include button('simple') {
	font-size: 16px !important;
	line-height: 20px !important;
	text-decoration:none!important;
	text-transform:none!important;
	letter-spacing: 0 !important;
	padding:0px!important;
	border:0px!important;
	box-shadow:none!important;
	text-shadow:none!important;
	min-width:0px!important;
	color: color('primary') !important;
	$fill_color: str-replace( #{color('primary')}, '#', '' );
	&:after {
		content:"";
		display: inline-block;
		margin-left: 4px;
		margin-top: 1px;
		background-image: url("data:image/svg+xml,%3Csvg fill='%23#{ $fill_color }' aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-right' class='svg-inline--fa fa-chevron-right fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='#{ $fill_color }' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'%3E%3C/path%3E%3C/svg%3E");
		background-position:center;
		background-size:auto;
		background-repeat:no-repeat;
		transform: translateX(0);
		width:12px;
		height:12px;
		transition: transform .3s ease-in-out;
	}
	&:hover,
	&:focus,
	&:focus-within {
		background: none !important;
		color: color('primary') !important;
		&:after {
			transform: translateX(5px);
		}
	}
}

@include media-loop() {
	.#{$media}all--color--white .btn--simple,
	.#{$media}all--color--white .all--btn--simple .hs-cta-wrapper a {
		color:color('white')!important;
	}
	.#{$media}all--color--white .btn--simple:after,
	.#{$media}all--color--white .all--btn--simple .hs-cta-wrapper a:after {
		background-image:url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="%23FFFFFF" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>');
	}
}

@include media-query('>tablet') {
	.block--multibutton {
		display:flex;
		display:inline-flex;
		-ms-flex-direction:row;
		flex-direction:row;
		align-items:center;
		justify-content:left;
		flex-wrap:wrap;
		gap: 12px;
	}
	.all--text--center .block--multibutton {
		justify-content:center;
	}
}
@include media-query('palm') {
	.block--multibutton--btn {
		display:block;
	}
	.block--multibutton--btn + .block--multibutton--btn {
		margin-top:12px;
	}
}
