// main: ../production.scss

/* ********** COLORS ********** */

// Background Images
@each $background in $background--list {
    .background--#{nth($background,1)} {
        background: background(nth($background,1));
        background-size: background-size(nth($background,1));
    }
}
// Background Size
.#{$media}background--cover {background-size:cover!important;}
.#{$media}background--contain {background-size:contain!important;}

/* Colors */
@include color-loop {
    // Colors
    .color--#{$name}, .all--color--#{$name}, .all--color--#{$name} * {
        color: #{$color}!important;
    }
    // Backgrounds
    .background--#{$name} {
        background-color: #{$color};
    }
    // Borders
    .border--#{$name} {
        border-color: #{$color};
    }
}
.color--inherit, .all--color--inherit, .all--color--inherit * {
    color: inherit!important;
}
.border--transparent {
    border-color: transparent;
}

/* Social Colors */
@include social-loop {
    // Colors
    .color--#{$name}, .all--color--#{$name}, .all--color--#{$name} * {
        color: #{$color};
    }
    // Backgrounds
    .background--#{$name} {
        background-color: #{$color};
    }
    // Borders
    .border--#{$name} {
        border-color: #{$color};
    }
}

/* Borders */
$border--loop:'';
@for $i from 0 through 10 {
    @if ($i != 0) {
        $border--loop: $border--loop + ', '
    }
    $border--loop: $border--loop + '.border--' + $i + ', .border--top--' + $i + ', .border--bottom--' + $i + ', .border--left--' + $i + ', .border--right--' + $i
}
#{$border--loop} {
    border-style:solid;
}
@for $i from 0 through 10 {
    .border--#{$i} {
        border-width: #{$i}px;
    }
    .border--top--#{$i} {
        border-top-width: #{$i}px!important;
    }
    .border--bottom--#{$i} {
        border-bottom-width: #{$i}px!important;
    }
    .border--left--#{$i} {
        border-left-width: #{$i}px!important;
    }
    .border--right--#{$i} {
        border-right-width: #{$i}px!important;
    }
}
@for $i from 0 through 10 {
    .border-radius--#{$i} {
        border-radius: #{$i}px;
    }
}
.border-radius--circle {
    border-radius: 50%;
}
.border-radius--pill {
    border-radius:9999px;
}
