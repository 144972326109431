// main: ../production.scss

// ---
// Forms
// ---

// Forms styles here!

// See some responsive form strategies here:
// http://bradfrost.github.io/this-is-responsive/patterns.html
// Actually, just use all of those strategies.

// Here are the basic form selectors I usually style:
// (much inspired by the setup from http://themble.com/bones)

*:focus {
    outline: none;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: block;
	width: 100%;
	font-size: $input--font-size;
	font-family: $input--font-family;
	padding: $input--vertical-padding $input--horizontal-padding;
	height: $input--height;
	color: $input--color;
	background-color: $input--background-color;
	background-image: url('#{$select--dropdown-icon}');
	background-repeat: no-repeat;
	background-size: 10px 5px;
	background-position: right 15px center;

	&::-ms-expand {
		display: none;
	}

	option:checked {
		color: $input--color;
	}

}

label {
	.all--labels--white & {
		color: white;
	}
}

input[type="search"] {
	// Maybe add a nice search icon in the background?
}

textarea {
	overflow: auto;
	vertical-align: top;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	height: $input--height;
	width: 100%;
	border-color: $input--border-color !important;
	border-width: $input--border-width !important;
	border-radius: $input--border-radius;
	font-family: $input--font-family;
	font-size: $input--font-size;
	line-height: $input--line-height;
	font-weight: $input--font-weight;
	text-transform: $input--text-transform;
	letter-spacing: $input--letter-spacing;
	word-spacing: $input--word-spacing;
	@if ($input--box-shadow != null ) {
		box-shadow: $input--box-shadow;
	}
	padding:$input--vertical-padding $input--horizontal-padding;
	margin-bottom: $input--spacing;
	color: $input--color;
	background-color: $input--background-color;

	&:focus {
		border-color: inherit;
	}

	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: $input--color;
		opacity: 1; /* Firefox */
	  }

	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: $input--color;
	  }

	&::-ms-input-placeholder { /* Microsoft Edge */
		color: $input--color;
	}

	.production-form--light-bg & {
		border-color: #e2e2e2;
		border-width: 1px;
		border-radius: $input--border-radius;
	}
}

input[type="radio"],
input[type="checkbox"] {
	line-height: normal;
	cursor: pointer;
}

input[type="file"],
input[type="image"],
input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"] {
	font-size: $input--font-size;
	font-family: $input--font-family;
}

input[type="submit"],
button {
	-webkit-transition: all .25s ease-in-out;
  	-moz-transition: all .25s ease-in-out;
  	-o-transition: all .25s ease-in-out;
  	transition: all .25s ease-in-out;
}

select:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
	outline: none;
}

.all--labels--white {
	label {
		color: color('white') !important;
	}
}

.hbspt-form {
	width: 100%;
	box-sizing: border-box;
	max-width: 100%;
	overflow:hidden;
}
form.hs-form {
	display: block;
	box-sizing: border-box;
	* {
		box-sizing: border-box !important;
	}
	width: 100% !important;
	.input {
		width: 100%;
		input:not(.hs-button) {
			text-align: left;
			display: block;
			color: color('grey') !important;
			height: $btn--line-height * 3;
		}
	}
	fieldset {
		display: block;
		float: none;
		width: 100%;
		max-width: 100%;
		&.form-columns-1 {
			display: block;
			.hs-form-field {
				width: 100%;
			}
		}
		&.form-columns-2 {
			display: block;
			.hs-form-field {
				display: inline-block;
			}
		}
	}

	.hs-form-field {
		float: none !important;
		max-width: unset;
		padding: 15px 5px;
		position: relative;
		input:not(.hs-button),
		textarea,
		select {
			margin-bottom: 0;
			display: block;
			width: 100% !important;
			max-width: 100%;
		}
		> label > span {
			font-size: $input--font-size !important;
		}
		.hs-error-msgs {
			list-style: none;
			padding-left: 0;
			position: absolute;
			bottom: -30px;
			width: 100%;
			padding: 0 5px;
			text-align: right;
			li {
				font-size: 13px;
				color: red;
			}
		}
	}

	.hubspot-link__container.sproket {
		display: none;
	}

	input.hs-button {
		display:inline-block;
		color: $btn--color;
		background-image:none;
		background-color: color('primary');
		text-align: center;
		font-family: $btn--font-family;
		font-size: $btn--font-size;
		line-height: $btn--line-height;
		font-style: $btn--font-style;
		font-weight: $btn--font-weight;
		text-transform: $btn--text-transform;
		text-decoration: $btn--text-decoration;
		letter-spacing: $btn--letter-spacing;
		word-spacing: $btn--word-spacing;
		padding: $btn--vertical-padding $btn--horizontal-padding !important;
		border-radius: $btn--border-radius;
		min-width: $btn--min-width;
		width:auto;
		max-width:100%;
		border-width:$btn--border-width !important;
		border-style:solid!important;
		border-color:$btn--color;
		-webkit-box-shadow: $btn--box-shadow !important;
		-moz-box-shadow: $btn--box-shadow !important;
		box-shadow: $btn--box-shadow !important;
		box-shadow: inset 0 0 0 0 !important;
		text-shadow:  $btn--box-shadow !important;
		background-image:none!important;
		outline:none!important;
		-moz-box-sizing:border-box!important;
		-webkit-box-sizing:border-box!important;
		box-sizing:border-box!important;
		text-shadow: none !important;
		@include transition(all .3s ease-in-out);
		margin-top: 20px;
	}

	select, input:not(.hs-button) {
		padding: 10px 30px 10px 15px !important;
		border: 1px solid #d7d7d7 !important;
	}
}

.wpcf7-form {
	input[type="submit"] {
		display:inline-block;
		color: $btn--color;
		background-image:none;
		background-color: color('primary');
		text-align: center;
		font-family: $btn--font-family;
		font-size: $btn--font-size;
		line-height: $btn--line-height;
		font-style: $btn--font-style;
		font-weight: $btn--font-weight;
		text-transform: $btn--text-transform;
		text-decoration: $btn--text-decoration;
		letter-spacing: $btn--letter-spacing;
		word-spacing: $btn--word-spacing;
		padding: $btn--vertical-padding $btn--horizontal-padding !important;
		border-radius: $btn--border-radius;
		min-width: $btn--min-width;
		width:auto;
		max-width:100%;
		border-width:$btn--border-width !important;
		border-style:solid!important;
		border-color:$btn--color;
		-webkit-box-shadow: $btn--box-shadow !important;
		-moz-box-shadow: $btn--box-shadow !important;
		box-shadow: $btn--box-shadow !important;
		box-shadow: inset 0 0 0 0 !important;
		text-shadow:  $btn--box-shadow !important;
		background-image:none!important;
		outline:none!important;
		-moz-box-sizing:border-box!important;
		-webkit-box-sizing:border-box!important;
		box-sizing:border-box!important;
		text-shadow: none !important;
		@include transition(all .3s ease-in-out);
		margin-top: 20px;
	}
}

.comment-form-cookies-consent {
	margin-bottom: 50px;
	input[type="checkbox"] {
		margin-right: 10px;
	}
}

textarea {
	min-height: 150px;
}
