
.search-results,
.search-no-results {

	.search--thumbnail {
		width: 100%;
		height: auto;
	}

	.searchbar--search--input {
		width: 75%;
		@media (max-width: 1047px) {
			margin-bottom: 20px;
		}
		input {
			margin-bottom: 0;
			height: 50px;
			border-color: color('white') !important;
			border-style: solid !important;
			@media (min-width:1048px) {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
	input[type="submit"] {
		@media (min-width:1048px) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

.search_autocomplete {
	text-align: left;
}
