//main: ../theme.scss

@media (max-width: 601px) {
  #wpadminbar {
    position: fixed !important;
  }
}

@include color-loop {
	a.hover--#{$name}:hover,
	.all--hover--#{$name}:hover a.text--link,
	.all--hover--#{$name}:hover span.box--link--cta,
	.all--hover--#{$name}:hover span.box--link--cta > i {
		-webkit-transition: color .25s ease-in-out;
		-moz-transition: color .25s ease-in-out;
		-o-transition: color .25s ease-in-out;
		transition: color .25s ease-in-out;
		color: color('#{$name}') !important;
	}
}

// Custom CSS
span, p, li {
	a {
		-webkit-transition: color .25s ease-in-out;
		-moz-transition: color .25s ease-in-out;
		-o-transition: color .25s ease-in-out;
		transition: color .25s ease-in-out;
		&:hover {
			color: lighten( $a--color, 20%);
		}
	}
}

pre {
	font-size: 14px !important;
	background: color('grey') !important;
	color: color('white') !important;
	text-align: left !important;
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////
// XCHOP CSS

.clear_0 {
  clear: both;
  font-size: 0px;
  line-height: 0px;
  display: block;
}

.last {
  margin: 0 !important;
}

.pad_left {
  padding-left: 0 !important;
}

.pad_last {
  padding: 0 !important;
}

.no_bg {
  background: none !important;
}

* {
  box-sizing: border-box;
}

/*=======================================================*/

a {
  color: #005da6;
  text-decoration: none;
  outline: 0 none;

  &:hover {
    color: #4d4d4d;
    text-decoration: none;
  }
}

// h1 {
//   font-size: 55px;
//   font-family: 'Open Sans', sans-serif;
//   color: #4d4d4d;
//   line-height: 1;
// }

// h2 {
//   font-size: 52px;
//   font-family: 'Open Sans', sans-serif;
//   color: #4d4d4d;
//   line-height: 1;
// }

// h3 {
//   font-size: 33px;
//   font-family: 'Open Sans', sans-serif;
//   font-weight: 600;
//   color: #4d4d4d;
//   line-height: 1;
// }

// h4 {
//   font-size: 32px;
//   font-family: 'Open Sans', sans-serif;
//   color: #4d4d4d;
//   line-height: 1;
// }

// h5 {
//   font-size: 26px;
//   font-family: 'Open Sans', sans-serif;
//   color: #4d4d4d;
//   line-height: 1;
// }

// h6 {
//   font-size: 18px;
//   font-family: 'Open Sans', sans-serif;
//   color: #4d4d4d;
//   line-height: 1;
//   font-weight: bold;
// }

// p {
//   font-size: 16px;
//   line-height: 1.5;
//   font-family: 'Open Sans', sans-serif;
//   font-weight: 400;
// }

// b {
//   font-size: 16px;
//   line-height: 1.5;
//   color: #4d4d4d;
//   font-family: 'Open Sans', sans-serif;
// }

/*=======================================================*/

html {}

body {
  color: #4d4d4d;
  background: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 400;
  margin: 0;
}

.clearfix {
  &:before {
    content: '';
    display: table;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

/*=======================================================*/

::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, :-moz-placeholder {
  color: #cccccc;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
}

/*=====SET: layout=====*/

.wrapper {
  margin: 0 auto;
}

.container {
  max-width: 1218px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

/*=====END: layout=====*/
/*=====SET: Default Globle CSS=====*/

.content {
  width: 100%;
  padding: 0 15px;
}

.midsec {
  position: relative;
  padding: 50px 0;
}

.midcolimg {
  position: relative;
  width: 100%;
  display: block;
  padding: 0 15px;

  img {
    width: auto;
    max-width: 100%;
    display: block;
    height: auto;
    object-fit: cover;
  }
}

.heading {
  width: 100%;
  margin-bottom: 25px;
}

.midcoltext {
  width: 100%;
  padding: 0;

  p {
    padding-bottom: 25px;
  }
}

.overlaytext p {
  color: #FFFFFF;
  padding-bottom: 0;
  font-size: 20px;
  line-height: 1.2;
}

.contact_btn {
  color: #FFFFFF;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
  background-color: transparent;
  text-align: center;
  padding: 0 15px;
  line-height: 1;
  border: 1px solid #005da6;

  &:hover {
    color: #6cb444;
    background-color: #005da6;
    border: 2px solid transparent;
  }
}

.learn_more {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #79b64f;
  border-radius: 3px 3px 3px 3px;
  padding: 19px 38px 19px 38px;
  border: 1px solid #79b64f;
  display: inline-block;
  vertical-align: top;

  &:hover {
    background-color: #539029;
    border-color: #539029;
    color: #FFFFFF;
  }
}

/*=====:ENd: Default Globle CSS=====*/

/*=====SET: BANNER SEC HERE=====*/

.herosec {
  width: 100%;
  height: 350px;
  margin-top: 0;
	@include media-query('palm') {
		height: 450px;
	}
  p {
    font-size: 23px;
  }
}

/*=====END: BANNER SEC HERE=====*/
/*=====SET: INDUSTRY HERO SEC HERE=====*/

.indherosec {
  width: 100%;
  margin-top: 104px;

  .indheading {
    p {
      font-size: 23px;
    }

    margin-top: 130px;
  }
}

/*=====END: INDUSTRY HERO SEC HERE=====*/
/*=====SET: FIRST SEC HERE=====*/

.firstsec {
  width: 100%;
  height: auto;
  position: relative;

  .midsec {
    padding: 80px 0;
  }

  h4 {
    margin-bottom: 15px;
    color: #57bbf9;
  }
}

/*=====END: FIRST SEC HERE=====*/
/*=====SET: SECOND SEC HERE=====*/

.secondsec {
  text-align: left;
  position: relative;
}

.icons {
  width: 64px;
  position: absolute;
  top: 0;
  left: 0;

  i {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }
}

.otc_techno_sec {
  p a {
    color: #FFFFFF;

    &:hover {
      color: #005da6;
    }
  }

  .elementor-container {
    height: 517px;
  }
}

/*=====END: SECOND SEC HERE=====*/
/*=====SET: THIRD SEC HERE=====*/

.thirdsec {
  width: 100%;
  height: auto;
  position: relative;
  text-align: right;

  h3, p {
    color: #FFFFFF;
  }
}

.video_secs .elementor-element-76562d5 {
  font-size: 18px;
  color: #5e5e5e;
}

/*=====END: THIRD SEC HERE=====*/
/*=====SET: FORTH SEC HERE=====*/

.forthsec {
  width: 100%;
  height: auto;
  position: relative;
  text-align: left;
}

.testimonial_sec {
  .elementor-element-8a8e263 {
    margin-bottom: 0;
  }

  .elementor-button {
    position: relative;

    i {
      font-size: 10px;
    }

    span.elementor-button-content-wrapper {
      padding-right: 40px !important;
      padding-left: 10px;
      position: relative;
      display: block;
    }

    .elementor-align-icon-right {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s;
      margin-left: 0;
    }

    &:hover .elementor-align-icon-right {
      right: 10px;
    }
  }

  .elementor-image {
    position: relative;

    &:before {
      position: absolute;
      left: 50%;
      margin-left: -45px;
      content: "\f10d";
      font-family: FontAwesome;
      font-size: 14px;
      color: #5e5e5e;
      top: -5px;
      width: 30px;
      height: 30px;
      text-align: center;
      border-radius: 100%;
      background-color: #FFFFFF;
      line-height: 30px;
    }
  }
}

.logo_car_sec {
  figure, .elementor-widget:not(.elementor-widget-text-editor) figure {
    margin-bottom: 25px;
  }

  .elementor-slick-slider {
    .slick-prev {
      left: -35px;
    }

    ul.slick-dots {
      bottom: -60px;
    }
  }

  .elementor-widget:not(.elementor-widget-text-editor) figure {
    margin-bottom: 0;
  }
}

.self_sec .elementor-element-populated {
  .elementor-widget-html {
    text-align: center;
  }

  i {
    font-size: 44px;
    color: #005da6;
  }

  .elementor-column {
    .elementor-button {
      position: relative;
      padding-left: 15px !important;
      padding-right: 15px !important;

      i {
        font-size: 10px;
      }

      .elementor-align-icon-right {
        position: absolute;
        right: -7px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s;
        margin-left: 0;
      }
    }

    &:hover .elementor-button {
      text-decoration: underline;

      .elementor-align-icon-right {
        right: -12px;
      }
    }
  }
}

/*=====END: FORTH SEC HERE=====*/

.featured_sec {
  position: relative;
  height: 517px;

  &:before {
    background-color: transparent;
    background-image: linear-gradient(133deg, #005da6 0%, #e66b3f 100%);
    opacity: 0.85;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    content: "";
  }

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.nobanner {
  position: relative;
  min-height: 450px;
  background-color: #ececed;
  width: 100%;
}

.banner_caption {
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  left: 0;
  right: 0;
  max-width: 1218px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  top: 50%;
  transform: translateY(-50%);

  h1 {
    color: #FFFFFF;
    line-height: 1.4;
    color: #FFFFFF;
    line-height: 1.4;
  }

  h2, h3, h4, h5, h6 {
    color: #FFFFFF;
    line-height: 1.4;
  }
}

.pg_contents h3 {
  color: #005da6;
  margin-bottom: 20px;
  line-height: 1.2;
}

.bg_cnt {
  margin-bottom: 30px;
}

.paginationsec {
  width: 100%;
  text-align: left;
  padding-top: 23px;
}

.navigation {
  h2.screen-reader-text {
    display: none;
  }

  float: none;
  text-align: left;
  width: 100%;
  margin: 0;

  .nav-links {
    a svg {
      display: none;
    }

    .page-numbers {
      padding: 5px 10px;
      border: 1px solid #ececec;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      font-size: 16px;
      line-height: 1.4;
      margin: 0 3px;
      text-decoration: none;
      display: inline-block;
      vertical-align: top;

      &.current, &:hover {
        background-color: #005da6;
        color: #FFFFFF;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border: 1px solid transparent;
      }
    }
  }
}

/*=====SET: CAREERS HERE=====*/

.key_statistics p, .top_description p {
  font-size: 23px;
  line-height: 31px;
}

.view_open_sec p {
  font-size: 18px;
  line-height: 25px;
}

/*=====END: CAREERS HERE=====*/
/*=====SET: CONTACT HERE=====*/

.bottom_box {
  position: absolute;
  bottom: -200%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.heading_sec {
  position: relative;

  .heading {
    position: relative;
    border-bottom: 5px solid #005DA6;
    overflow: hidden;
    margin-bottom: 10px;

    > .elementor-element-populated {
      position: relative;
      background-color: #005DA6;
      -webkit-transform: skew(30deg);
      -moz-transform: skew(30deg);
      -o-transform: skew(30deg);
      display: inline-block;
      vertical-align: top;
      width: auto;
      margin-left: -10px;
    }

    h5.elementor-heading-title {
      position: relative;
      display: inline-block;
      vertical-align: top;
      -webkit-transform: skew(-30deg);
      -moz-transform: skew(-30deg);
      -o-transform: skew(-30deg);
      padding: 10px 20px 0 25px;
    }
  }
}

.eael-adv-accordion .eael-accordion-list {
  .eael-accordion-header {
    text-align: center;
    display: block;
    color: #5E5E5E !important;
    background-color: rgba(0, 93, 166, 0.052992) !important;
  }

  &:hover .eael-accordion-header .fa-toggle {
    color: #5E5E5E !important;
  }

  .eael-accordion-header {
    .fa-toggle {
      color: #5E5E5E !important;
      transform: rotate(0) !important;
    }

    &:focus {
      outline: none;
    }

    &.active {
      i {
        color: #5E5E5E !important;
        margin: 0;

        &:before {
          content: "\f068" !important;
        }
      }

      background-color: rgba(0, 93, 166, 0.05) !important;
      border-bottom-color: transparent !important;
      font-weight: 600;

      .fa-toggle {
        transform: rotate(0);
      }
    }
  }

  .eael-accordion-content {
    background-color: rgba(0, 93, 166, 0.05) !important;
    border-top-color: transparent !important;
    color: #5E5E5E !important;
    padding: 20px 30px;
  }
}

.eael-accordion-list .eael-accordion-header span {
  display: inline-block;
  vertical-align: top;
  padding: 0 15px;
}

.locatino_list_sec .midcolimg {
  padding: 0;
  height: 100%;

  .elementor-widget-container {
    height: 100%;

    .elementor-image {
      height: 100%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*=====END: CONTACT HERE=====*/

.team_listsec {
  .cta-btn {
    font-family: "Open Sans",Sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    font-style: normal;
    line-height: 20px;
    color: #005da6;
    background-color: transparent;
    padding: 0 30px 0 0;
    border: none;
    position: relative;

    i {
      margin-left: 10px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      transition: all .3s;
      font-size: 10px;
    }

    &:hover {
      background-color: transparent;
      color: #005da6;
      text-decoration: underline;
      border: none;

      i {
        right: 0;
      }
    }
  }

  .box:hover .cta-btn {
    background-color: transparent;
    color: #005da6;
    text-decoration: underline;
    border: none;

    i {
      right: 0;
    }
  }
}



.accordion_sec p a {
  color: #4d4d4d;

  &:hover {
    color: #005DA6;
  }
}

.contact_team p, .team_header p {
  font-size: 18px;
  line-height: 25px;
}

.pie-title-center {
  display: inline-block;
  position: relative;
  text-align: center;
}

.pie-value {
  display: block;
  position: absolute;
  font-family: "Open Sans",Sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  line-height: 1;
  color: #005DA6;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

/*=====SET: TAB SEC HERE=====*/

.buy_tab {
  .tab-sub-title {
    color: #5e5e5e;
    margin-top: 20px;
  }

  .eael-tab-title {
    text-align: left;
  }

  .eael-tabs-nav {
    ul li {
      margin-right: 40px;
      max-width: 350px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .eael-advance-tabs .eael-tabs-nav > ul {
    z-index: auto;
  }

  .eael-tabs-nav > ul li {
    &.active, &.active-default {
      box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.1);
      z-index: 2;
    }

    &.inactive {
      z-index: 0;
    }
  }

  .eael-tabs-content {
    .active, .active-default {
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  }
}

.ind_tab .eael-advance-tabs {
  .eael-tabs-nav > ul li {
    padding: .5em 4.5em .5em .5em;
  }

  .li1 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .eael-tabs-content > div {
    padding-bottom: 80px;
  }
}

/*=====END: TAB SEC HERE=====*/
/*=====SET: RELATED POSTS SEC HERE=====*/

.elementor-posts .elementor-post__avatar img {
  position: absolute;
  right: 20px;
}

/*=====END: RELATED POSTS SEC HERE=====*/

/*===== SET: SIDEBAR TAB PULLOUT MENU =====*/

.view-products-container.std-drawer {
  background: url(/wp-content/uploads/2019/11/view-products-container-bg.png);
}

.view-products-tab.std-tab {
  background: url(/wp-content/uploads/2019/11/view-products-bg-default.png);
}

.std-status-open .view-products-tab.std-tab {
  background: url(/wp-content/uploads/2019/11/view-products-bg.png);
}

.view-products-container {
	h2.widget-title {
		color: color('white') !important;
	}

  .menu {
    width: 95%;
  }

  .widget li {
    border-bottom: 1px dotted #ffffff;
    padding: 20px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: 0;
		}
		a {
			font-size: 16px;
		}
	}
	&-products {
		h2.widget-title {
			color: color('white') !important;
		}
	}
}

@media only screen and (max-width: 767px) {
  #std-idseq-1 {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  #std-idseq-1 {
    display: block !important;
  }
}

img.spacer {
	display: block;
  filter: alpha(opacity=0);
  height: auto;
  opacity: 0;
  visibility: hidden;
  width: 100%;
}

.im--breadcrumbs {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	.text__link {
		color: color('darkgrey');
		display: inline-block;
		line-height: 1em;
		padding: 0;
		position: relative;
		font-family: font('reading');
		font-size: 16px;
		svg {
			display: block;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			opacity: 0;
			width:100%;
			height: 1px;
			background: color('grey');
			transition: opacity .3s ease-in-out;
		}
		&:first-child {
			text-decoration: none;
			svg path {
				fill: color('darkgrey');
			}
		}
		&:hover {
			color: color('darkgrey');
			&:after {
				opacity: 1;
			}
		}
		.all--color--white & {
			color: color('white');
			&:first-child {
				svg path {
					fill: color('white');
				}
			}
			&:hover {
				&:after {
					background: color('white');
				}
			}
		}
		@include media-query('palm') {
			font-size: 16px;
		}
	}
	.separator {
		display: flex;
		align-items: center;
		line-height: 1em;
		margin: 0 9px;
		padding: 0;
		font-size: 16px;
		path {
			fill: color('darkgrey');
		}
		@include media-query('palm') {
			font-size: 16px;
		}
		.all--color--white & {
			path {
				fill: color('white');
			}
		}
	}
	.current--location {
		display: inline-block;
		line-height: 1em;
		padding: 0;
		font-size: 16px;
		font-weight: 400;
		.all--color--white & {
			color: color('lightgrey') !important;
		}
		@include media-query('palm') {
			font-size: 16px;
		}
	}
}

.woocommerce-breadcrumb {
	.crumb {
		font-size: 16px;
		&:first-child {
			a {
				text-decoration: none;
				&::before {
					font-family: font('fontawesome') !important;
					content: '\f015';
				}
			}
		}
		&:last-child{
			&:after {
				display: none !important;
			}
		}
		@include media-query('palm') {
			font-size: 16px;
		}
	}
}
