// main: ../production.scss

$grid-shorthand-list: one-whole, one-half, one-third, one-fourth, one-fifth, one-sixth;

// String replace
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

// Loop for returning the colors list.
@function color($color) {
    $index: null;
    @each $colorItem in $social--color--list {
        @if (unquote(nth($colorItem,1)) == '#{$color}') {
            $index: nth($colorItem,2);
        }
    }
    @each $colorItem in $color--list {
        @if (unquote(nth($colorItem,1)) == '#{$color}') {
            $index: nth($colorItem,2);
        }
    }
    @if ($index == null) {
        @error "Color '#{$color}' not defined";
    }
    @return $index;
}

// Loop for generating colors
$name:null;
$color:null;
$important:null;
@mixin color-loop() {
    @each $colorItem in $color--list {
        $name: unquote(nth($colorItem,1))!global;
        $color: color(unquote(nth($colorItem,1)))!global;
        //$important: null!global;
        @content;
    }
}
@mixin social-loop() {
    @each $colorItem in $social--color--list {
        $name: unquote(nth($colorItem,1))!global;
        $color: color(unquote(nth($colorItem,1)))!global;
        //$important: null!global;
        @content;
    }
}

// Loop for returning the backgrounds list.
@function background($background) {
    $index: null;
    @each $backgroundItem in $background--list {
        @if (nth($backgroundItem,1) == '#{$background}') {
            $index: nth($backgroundItem,2);
            @if (str-index($index, "color(") != null) {
                $start-at: str-index($index, "color(")+6;
                $end-at: str-index($index, ")")-1;
                $index-color: str-slice($index, $start-at, $end-at);
                @if (str-index($index-color, "'") != null) {
                    $start-at: 2;
                    $end-at: str-length($index-color)-1;
                    $index-color: str-slice($index-color, $start-at, $end-at);
                    $index: str-replace($index,"color('"+$index-color+"')",color($index-color));
                }
                @else {
                    $index: str-replace($index,"color("+$index-color+")",color($index-color));
                }
            }
        }
    }
    @if ($index == null) {
        @error "Background '#{$background}' not defined";
    }
    @return unquote($index);
}
@function background-size($background) {
    $index: null;
    @each $backgroundItem in $background--list {
        @if (nth($backgroundItem,1) == $background) {
            $index: nth($backgroundItem,3);
        }
    }
    @if ($index == null) {
        @error "Background size '#{$background}' not defined";
    }
    @return unquote($index);
}
@function background-src($background) {
    $index: null;
    @each $backgroundItem in $background--list {
        @if (nth($backgroundItem,1) == $background) {
            $index: background(nth($backgroundItem,1));

            $start-at: str-index($index, "url('")+5;
            $end-at: str-index($index, "')")-1;
            $index: str-slice($index, $start-at, $end-at);
        }
    }
    @if ($index == null) {
        @error "Background '#{$background}' not defined";
    }
    @return unquote($index);
}


// Loop for returning the fonts list.
@function font($font) {
    $index: null;
    @each $fontItem in $font--list {
        @if (nth($fontItem,1) == '#{$font}') {
            $index: nth($fontItem,2);
        }
    }
    @if ($index == null) {
        @error "Font '#{$font}' not defined";
    }
    @return $index;
}

// Loop for generating Media Queries
$media:null;
$important:null;
$mediaImportant:!important;
@mixin media-loop() {
    $media:null!global;
    $mediaMin: null;
    $mediaMax: null;
    $mediaImportant:null!global;

    @each $mediaItem in $media--list {
        $media: nth($mediaItem,1)+"-"!global;
        $mediaMax: nth($mediaItem,2);
        $mediaMin: nth($mediaItem,3);
        $important: !important!global;
        $mediaImportant: !important!global;
        @if ($mediaMax != null) {
            $mediaMax: nth($mediaItem,2)+'px';
        }
        @if ($mediaMin != null) {
            $mediaMin: nth($mediaItem,3)+'px';
        }

        @if (($mediaMin == null)and($mediaMax == null)) {
            $media: null!global;
            $mediaImportant: null!global;
            //$important: null!global;
            @content;
        } @else if (($mediaMax)and($mediaMin == null)) {
            @media screen and (max-width:$mediaMax) { @content; }
        }
        @else if (($mediaMax == null)and($mediaMin != null)) {
            @media screen and (min-width:$mediaMin) { @content; }
        }
        @else {
            @media screen and (max-width:$mediaMax) and (min-width:$mediaMin) { @content; }
        }
    }
    $media:null!global;
}

// Function for converting integer to corresponding word
@function gridNumToString($i) {
    @if ($i == 1) {@return 'one';}
    @else if ($i == 2) {@return 'two';}
    @else if ($i == 3) {@return 'three';}
    @else if ($i == 4) {@return 'four';}
    @else if ($i == 5) {@return 'five';}
    @else if ($i == 6) {@return 'six';}
    @else if ($i == 7) {@return 'seven';}
    @else if ($i == 8) {@return 'eight';}
    @else if ($i == 9) {@return 'nine';}
    @else if ($i == 10) {@return 'ten';}
    @else if ($i == 11) {@return 'eleven';}
    @else if ($i == 12) {@return 'twelve';}
    @else {@error "Grid sizes '#{$i}' invalid. Must be an integer from 1 and 12.";}
}

@function gridStringToNum($i) {
    @if ($i == "one-whole") {@return 1;}
    @else if ($i == "one-half") {@return 1/2;}
    @else if ($i == "one-third") {@return 1/3;}
    @else if ($i == "one-fourth") {@return 1/4;}
    @else if ($i == "one-fifth") {@return 1/5;}
    @else if ($i == "one-sixth") {@return 1/6;}
    @else {@error "Grid sizes '#{$i}' invalid.";}
}

// Button Shading
@function button-shading($color) {
    @if ($btn--shading < 0) {
        @return darken($color, abs($btn--shading));
    } @else if ($btn--shading >= 0) {
        @return lighten($color, abs($btn--shading));
    }
}
