// main: ../production.scss

// ---
// Mixins
// ---


// These are mixins!


// Media Queries
// http://css-tricks.com/conditional-media-query-mixins/

// A breakpoint mixin is super useful for nesting within other selectors:

// Love me a clearfix.

@mixin clearfix() {
	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}
	&:after {
		clear: both;
	}
}

@mixin transition-delay($duration) {
    -webkit-transition-delay: #{$duration};
    transition-delay: #{$duration};
}

// Mixin for individual Media Query
@mixin media-query($mediaType) {
    $mediaType: inspect($mediaType);
    @if ($mediaType == 'touch') {
        @at-root html.device--touch #{&} {
            @content;
        }
    }
    @else {
        @each $mediaItem in $media--list {
            $mediaMax: nth($mediaItem,2);
            $mediaMin: nth($mediaItem,3);
            //@error "'>#{nth($mediaItem,1)}'";
            @if ($mediaType == inspect(">"+nth($mediaItem,1))) {
                @if ($mediaMin != null) {
                    $mediaMin: nth($mediaItem,3)+'px';
                    @media screen and (min-width:$mediaMin) { @content; }
                }
                @else {@error "Media query breakpoint '#{$mediaType}' has no min width.";}
            }
            @else if ($mediaType == inspect("<"+nth($mediaItem,1))) {
                @if ($mediaMax != null) {
                    $mediaMax: nth($mediaItem,2)+'px';
                    @media screen and (max-width:$mediaMax) { @content; }
                }
                @else {@error "Media query breakpoint '#{$mediaType}' has no max width.";}
            }
            @else if (($mediaType == inspect(nth($mediaItem,1)))or($mediaType == inspect(""+nth($mediaItem,1)))) {
                @if ($mediaMax != null) {
                    $mediaMax: nth($mediaItem,2)+'px';
                }
                @if ($mediaMin != null) {
                    $mediaMin: nth($mediaItem,3)+'px';
                }

                @if (($mediaMin == null)and($mediaMax == null)) {
                    @content;
                } @else if (($mediaMax)and($mediaMin == null)) {
                    @media screen and (max-width:$mediaMax) { @content; }
                }
                @else if (($mediaMax == null)and($mediaMin != null)) {
                    @media screen and (min-width:$mediaMin) { @content; }
                }
                @else {
                    @media screen and (max-width:$mediaMax) and (min-width:$mediaMin) { @content; }
                }
            }
        }
    }
}

// Button Mixin
@mixin button($selector:"") {
	@if ($selector != "") {$selector: "--"+$selector;}
		.#{$media}btn#{$selector}, 
		.#{$media}all--btn#{$selector} input[type="submit"].wpcf7-submit,
		.#{$media}all--btn#{$selector} form.wpcf7-form  input[type="submit"].wpcf7-submit,
		.#{$media}all--btn#{$selector} form.hs-form  input.hs-button,
		.#{$media}all--btn#{$selector} div.sb-loadmore,
		.#{$media}all--btn#{$selector}.four-oh-four-menu a {
			@content;
	}
}

// Line Clamp Mixin
@mixin line-clamp($lines) {
    overflow:hidden;
    text-overflow:ellipsis;
    display: -webkit-box;
    -webkit-line-clamp:#{$lines};
    -webkit-box-orient: vertical;
}

// Box Sizing Mixin
@mixin box-sizing($style) {
    -moz-box-sizing:#{$style};
    -webkit-box-sizing:#{$style};
    box-sizing:#{$style};
}

@mixin box-shadow($args, $inset: false) {
	@if $inset {
		-webkit-box-shadow:inset $args;
		-moz-box-shadow:inset $args;
		box-shadow:inset $args;
	} @else {
		-webkit-box-shadow: $args !important;
		-moz-box-shadow: $args !important;
		box-shadow: $args !important;
	}
}

@mixin transform($args) {
	-moz-transform: $args;
	-ms-transform: $args;
	-webkit-transform: $args;
	transform: $args;
}

@mixin transition($args) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-o-transition: $args;
	transition: $args;
}
