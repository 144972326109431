.woocommerce-page.single-product {
	nav.woocommerce-breadcrumb {
		padding: 20px 0;
		border-bottom: 1px solid #e2e2e2;
		margin: 0 auto 60px;
	}
	div.product {
		text-align: left;
		padding-top: 30px !important;
		display: flex !important;
		flex-flow: row !important;
		@include media-query('palm') {
			flex-flow: column !important;
		}
		div.woocommerce-product-gallery {
			float: none !important;
			display: inline-block;
			vertical-align: top;
			flex-basis: 100%;
			@include media-query('lap-and-up') {
				width: 25%;
				flex-basis: 25%;
			}
		}
		div.summary {
			float: none !important;
			display: inline-block;
			vertical-align: top;
			padding: 0px;
			flex-basis: 100%;
			@include media-query('>tablet') {
				padding: 0 0 0 30px;
			}
			@include media-query('lap-and-up') {
				padding: 0 0 0 30px;
				width: 75%;
				flex-basis: 75%;
			}
			ul {
				padding-left: 30px;
			}
		}
	}
	@media (max-width: 1360px) {
		.content-area {
			.grid {
				max-width: 90%;
				margin: 0 auto;
			}
		}
	}
	.product--share--button {
		position: relative;
		overflow: visible;
		#product--social--wrapper {
			position: absolute;
			left: -10px;
			top: 100%;
			padding: 10px;
			width: 90px;
			background: #f7f7f7;
			padding: 10px;
			box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, .1);
			text-align: center;
			border-radius: 5px;
			.share--item {
				margin: 0 2px;
			}
		}
	}
}

.product--key--features,
.product--applications {
	p+ul {
		margin-top: -15px;
	}
	ul {
		list-style: none;
		padding-left: 0 !important;
		li {
			display: block;
			padding-left: 30px;
			padding-top: 15px;
			padding-bottom: 15px;
			position: relative;
			border-bottom: 1px solid color('mediumgrey');
			&:before {
				display: block;
				content: '\f00c';
				font-family: font('fontawesome');
				font-size: 12px;
				color: color('grey');
				position: absolute;
				left: 0;
				top: 15px;
				font-weight: 900;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
	}
}
